import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  display: flex;
  min-width: max-content;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  & + & {
    border-top: 1px solid var(--gray-200);
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Content = styled.div`
  padding: 1rem;
  width: 100%;
  header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  > main {
    padding: 1rem;
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  width: 1rem;
  height: 1rem;
  white-space: nowrap;
`;

export const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-right: 1rem;
`;
