import { Dispatch, SetStateAction, useState } from "react";
import ReactModal from "react-modal";
import {
  Button,
  CloseIcon,
  Container,
  Content,
  Header,
  SendBox,
} from "./styles";
import { Chips } from "primereact/chips";
import { useDispatch, useSelector } from "react-redux";
import { sendDocToEmails } from "../../store/modules/causes/actions";

interface SendDocToEmailModalProps {
  isOpen: boolean;
  setIsClosed: Dispatch<SetStateAction<boolean>>;
  causeId: number;
}

const SendDocToEmailModal = ({
  isOpen,
  setIsClosed,
  causeId,
}: SendDocToEmailModalProps) => {
  const [values, setValues] = useState<any>([]);
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.dashboard.table.loading);

  function handleSubmit(causeId: number, stringOfEmails: string) {
    dispatch(sendDocToEmails({ causeId, stringOfEmails }));
    setIsClosed(!isOpen);
    setValues([]);
  }

  return (
    <ReactModal
      isOpen={isOpen}
      className="Modal"
      overlayClassName="Overlay"
      ariaHideApp={false}
      onRequestClose={() => setIsClosed(!isOpen)}
    >
      <Container>
        <Content>
          <Header>
            <h2>Insira os emails: </h2>
            <CloseIcon
              size={30}
              color="var(--primary)"
              onClick={() => setIsClosed(!isOpen)}
            />
          </Header>
          <p>por favor, insira seus e-mails separado por virgula</p>
          <SendBox>
            <Chips
              value={values}
              onChange={(e) => setValues(e.value)}
              separator=","
              placeholder="Insira seus Emails"
            />
            <Button
              loading={loading}
              onClick={() => handleSubmit(causeId, values.toString())}
            >
              Enviar
            </Button>
          </SendBox>
        </Content>
      </Container>
    </ReactModal>
  );
};

export default SendDocToEmailModal;
