import { takeLatest, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "../../../services/api";
import {
  updateTableFailure,
  updateTableSuccess,
  tableLoadingTrue,
  tableLoadingFalse,
} from "./actions";
import history from "../../../services/history";
import { Cause } from "../../../types/cause";
import { signOut } from "../auth/actions";

export function* updateTable({ payload }: any) {
  let {
    page = 0,
    itemsQnt = 10,
    step = "",
    status = "",
    sort = "",
    order = "",
    email_or_name = "",
  } = payload;
  try {
    yield put(tableLoadingTrue());

    const { data } = yield api.get(
      `/api/v1/causes?items=${itemsQnt}&page=${page}${
        step ? `&step=${step}` : ""
      }${status ? `&status=${status}` : ""}${sort ? `&sort=${sort}` : ""}${
        order ? `&order=${order}` : ""
      }${email_or_name ? `&email_or_name=${email_or_name}` : ""}`
    );
    let { meta } = data;
    if (meta === undefined) {
      itemsQnt = meta.pagination.count;
      meta = {
        pagination: {
          page: 1,
          pages: 1,
          last: 1,
          from: 1,
          items: 0,
          to: 0,
        },
      };
    }

    const parsedData: Cause[] = data?.data?.map((item: Cause) => {
      return {
        id: item.id,
        clientId: item.client_id,
        name: item.client.name,
        cadastroDate: item.created_at,
        email: item.client.email,
        limitDate: item.informations.deadline,
        in_analysis: item.informations.in_analysis,
        docSent: item.informations.documents_sent,
        docValid: item.informations.validated_documents,
        step: item.step,
        status: item.status,
        demand: item.demand_code,
      };
    });
    yield put(tableLoadingFalse());
    if (itemsQnt === 0) {
      itemsQnt = 10;
    }
    yield put(
      updateTableSuccess({
        parsedData,
        page,
        itemsQnt,
        step,
        status,
        meta,
        email_or_name,
        sort,
        order,
      })
    );
    history.push("/causes");
  } catch (error: any) {
    toast.error("Causa com esse Status não encontrada");
    yield put(tableLoadingFalse());

    if (error.response.status === 401) yield put(signOut());

    yield put(updateTableFailure({ error, page, itemsQnt, step, status }));
  }
}

export function* sendDocumentsToEmail({ payload }: any) {
  const { causeId, stringOfEmails } = payload;

  try {
    yield put(tableLoadingTrue());
    const { data } = yield api.post(
      `/api/v1/causes/${causeId}/send_documents`,
      {
        emails: stringOfEmails,
      }
    );

    yield put(tableLoadingFalse());
    toast.success(data?.message?.sending_documents_email);
  } catch (error: any) {
    if (error.response.status === 401) yield put(signOut());
    toast.error("Não foi possível enviar");
  }
}

export default all([
  takeLatest("@dashboard/UPDATE_TABLE_REQUEST", updateTable),
  takeLatest("@drawer/SEND_EMAILS_REQUEST", sendDocumentsToEmail),
]);
