import { FiX } from "react-icons/fi";
import styled from "styled-components";
import AppButton from "../Button";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  background: #fff;
  padding: 1rem;
  border-radius: 4px;

  > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 23px;
  }
  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #374954;
    margin-bottom: 1rem;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-width: 25rem;
  svg {
    margin: 0;
    cursor: pointer;
  }
`;

export const CloseIcon = styled(FiX)`
  position: relative;
  top: 0;
  right: 0;
  transition: all 0.2s;
  margin: 1rem;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
    background: rgb(128, 128, 128, 0.8);
    border-radius: 50%;
  }
`;

export const SendBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin: 0 1rem;
    &:hover {
      filter: brightness(0.8);
      background: rgb(128, 128, 128, 0.8);
      border-radius: 50%;
    }
  }
`;

export const Button = styled(AppButton)`
  background: #e7004c;
  height: 40px;
  width: 80px;
  border: 0;
  margin: 0 1rem;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #ffffff;

  &:hover {
    filter: brightness(0.8);
  }
`;
