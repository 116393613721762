import { useState } from "react";
import { Container, Content, LoadingBox, NoDataBox } from "./styles";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaCircle, FaRegCircle } from "react-icons/fa";
import ReactLoading from "react-loading";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface ICarousel {
  items: Item[];
}

interface Item {
  src: string;
}

const Carrousel = ({ items }: ICarousel) => {
  const [index, setIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  async function handlePrevius() {
    setIsLoaded(false);
    if (index === 0) return null;
    setIndex(index - 1);
  }

  async function handleNext() {
    setIsLoaded(false);
    if (index === items.length - 1) return null;
    setIndex(index + 1);
  }

  if (items[index]?.src === undefined) {
    return (
      <NoDataBox>
        <div>
          <FiChevronLeft
            size={48}
            color={index === 0 ? "#A9A9A9" : "#E7004C"}
            onClick={handlePrevius}
          />
          <h1>Imagem não enviada pelo cliente</h1>
          <FiChevronRight
            size={48}
            color={index === items.length - 1 ? "#A9A9A9" : "#E7004C"}
            onClick={handleNext}
          />
        </div>
        <Content style={{ marginTop: "30px" }}>
          {index === 0 ? (
            <FaCircle size={14} color="#E7004C" onClick={() => setIndex(0)} />
          ) : (
            <FaRegCircle
              size={14}
              color="#E7004C"
              onClick={() => {
                setIsLoaded(false);
                setIndex(0);
              }}
            />
          )}
          {index === 1 ? (
            <FaCircle size={14} color="#E7004C" onClick={() => setIndex(1)} />
          ) : (
            <FaRegCircle
              size={14}
              color="#E7004C"
              onClick={() => {
                setIsLoaded(false);
                setIndex(1);
              }}
            />
          )}
          {index === 2 ? (
            <FaCircle size={14} color="#E7004C" onClick={() => setIndex(2)} />
          ) : (
            <FaRegCircle
              size={14}
              color="#E7004C"
              onClick={() => {
                setIsLoaded(false);
                setIndex(2);
              }}
            />
          )}
        </Content>
      </NoDataBox>
    );
  }

  return (
    <Container>
      <FiChevronLeft
        size={48}
        color={index === 0 ? "#A9A9A9" : "#E7004C"}
        onClick={handlePrevius}
      />

      <div>
        {isLoaded ? null : (
          <LoadingBox>
            <ReactLoading
              type="spinningBubbles"
              color="#E7004C"
              height="96px"
              width="96px"
            />
          </LoadingBox>
        )}

        <TransformWrapper>
          <TransformComponent contentStyle={{ width: "100%" }}>
            <img
              src={items[index]?.src}
              style={isLoaded ? {} : { display: "none" }}
              alt="Carroussel Img"
              onLoad={() => setIsLoaded(true)}
            />
          </TransformComponent>
        </TransformWrapper>
        <Content>
          {index === 0 ? (
            <FaCircle size={14} color="#E7004C" onClick={() => setIndex(0)} />
          ) : (
            <FaRegCircle
              size={14}
              color="#E7004C"
              onClick={() => {
                setIsLoaded(false);
                setIndex(0);
              }}
            />
          )}
          {index === 1 ? (
            <FaCircle size={14} color="#E7004C" onClick={() => setIndex(1)} />
          ) : (
            <FaRegCircle
              size={14}
              color="#E7004C"
              onClick={() => {
                setIsLoaded(false);
                setIndex(1);
              }}
            />
          )}
          {index === 2 ? (
            <FaCircle size={14} color="#E7004C" onClick={() => setIndex(2)} />
          ) : (
            <FaRegCircle
              size={14}
              color="#E7004C"
              onClick={() => {
                setIsLoaded(false);
                setIndex(2);
              }}
            />
          )}
        </Content>
      </div>
      <FiChevronRight
        size={48}
        color={index === items.length - 1 ? "#A9A9A9" : "#E7004C"}
        onClick={handleNext}
      />
    </Container>
  );
};

export default Carrousel;
