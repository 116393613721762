import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 20vw;
  justify-content: flex-end;
`;

export const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ HighlightThisPage }) => (HighlightThisPage ? "#e70044 " : "#fff")};
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-left: 5px;
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};

  color: ${({ HighlightThisPage }) => (HighlightThisPage ? "#fff " : "#7c878d")};

  &:hover {
    border: 1px solid #e70044;
    color: ${({ HighlightThisPage }) => (HighlightThisPage ? "#fff " : "#e70044")};
  }

  svg {
    opacity: ${({ disabled }) => (disabled ? "40%" : "100%")};
  }
`;
