import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  width: 50%;
  flex-direction: column;
  height: calc(100vh - 64px);
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-auto-rows: minmax(10rem, 5);
  overflow-y: scroll;
  overflow-x: hidden;
  grid-auto-flow: dense;
  min-width: max-content;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const Today = styled.span`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  width: 1rem;
  height: 1rem;
  white-space: nowrap;
`;
