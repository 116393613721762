import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 4.35rem;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;

  > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

    label {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 140%;
      color: #a6042b;
    }

    p {
      margin-left: 0.5rem;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 140%;
      color: #7c878d;
    }

    > span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #e7004c;
      text-decoration-line: underline;
      margin-left: 1.5rem;
      cursor: pointer;
      transition: filter 0.2s;
      &:hover {
        filter: brightness(0.5);
      }
    }
    svg {
      cursor: pointer;
      margin-left: 5px;
      &:hover {
        filter: brightness(0.5);
      }
    }
  }

  > p {
    visibility: ${({ active }) => (active ? 'hidden' : 'visible')};
    margin-top: 0.5rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #374954;
  }
`;

export const Input = styled.input`
  margin-top: 0.5rem;
  width: 50%;
  padding: 0px;
  height: 3rem;
  background: #f4f5f5;
  border: 1px solid #aab6bc;
  box-sizing: border-box;
  border-radius: 4.8px;
  padding: 0.4rem 0.4rem;
`;

export const InputBox = styled.div`
  transition: height 0.2s ease-out;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  height: ${({ active }) => (active ? "3rem" : 0)};
`;
