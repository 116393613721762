import produce from "immer";

const INITIAL_STATE = {
  visible: false,
  loading: false,
  openedWith: '',
  documentsInAnalyze: [],
  document: {
    url: "",
    history: "",
    name: "",
    id: "",
    observation: "",
    type: "",
    option: "",
    status: "",
  },
  clientIdentity: {
    id: "",
    data: {},
    images: [],
    name: "",
    phone: "",
    askForCpf: "",
    docType: "",
  },
  type: "cause",
};

export default function drawer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@drawer/CHANGE_IS_VISIBLE":
        draft.visible = !state.visible;
        break;
      case "@drawer/START_ANALYZE":
        draft.openedWith = action.payload.type;
        draft.documentsInAnalyze = action.payload.documents;
        break;
      case "@drawer/ANALYZE_NEXT":
        break;
      case "@drawer/REFRESH_DOCUMENTSTOANALYZE_LIST":
        draft.documentsInAnalyze = action.payload;
        break;
      case "@drawer/CHANGE_LOADING_STATE":
        draft.loading = !state.loading;
        break;
      case "@drawer/CHANGE_TYPE":
        draft.type = action.payload;
        break;
      case "@drawer/CHANGE_DOCTYPE":
        draft.clientIdentity.docType = action.payload;
        break;
      case "@drawer/CHANGE_ASK_FOR_CPF":
        draft.clientIdentity.askForCpf = action.payload;
        break;
      case "@drawer/REQUEST_NEW_ANALIZE_SUCCESS":
        if (state.type === "cause") {
          draft.document.status = action.payload.document.status;
          draft.document.type = action.payload.document.content_type;
          draft.document.option = action.payload.option;
          draft.document.history = action.payload.document_histories;
          draft.document.name = action.payload.name;
          if (action.payload.document.checked === null) {
            draft.document.url = action.payload.archive.src;
            draft.document.observation = "";
          } else {
            draft.document.observation = action.payload.document.observation;
          }
          draft.document.id = action.payload.document.id;
        } else {
          draft.clientIdentity.data = action.payload.client_identity;
          draft.clientIdentity.id = action.payload.id;
          draft.clientIdentity.name = action.payload.name;
          draft.clientIdentity.phone = action.payload.phone;
          draft.clientIdentity.askForCpf = action.payload.client_identity.ask_for_cpf;
          draft.clientIdentity.docType = action.payload.client_identity.kind_identification;
          draft.clientIdentity.images = [];
          draft.clientIdentity.images.push(
            action.payload.client_identity.front
          );
          draft.clientIdentity.images.push(
            action.payload.client_identity.verse
          );
          draft.clientIdentity.images.push(
            action.payload.client_identity.selfie
          );
        }
        break;
      default:
    }
  });
}
