import styled from "styled-components";
import Button from "../../../components/Button";
import Input from "../../../components/Input";

export const Container = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  width: 80vw;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 1rem;

  header {
    margin-top: 15px;
    display: flex;
    width: 80vw;

    > div {
      border-radius: 4px;
      cursor: pointer;
      padding: 2px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: background 0.2s ease-in-out;
      &:hover {
        filter: brightness(0.9);
        background: #d8d8d8;
      }

      h1 {
        margin-left: 5px;
        color: var(--primary);
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.05em;
      }
    }
  }
`;

export const NewClientInput = styled(Input)`
  background: var(--gray-200);

  margin: 0.5rem 0;
  border: none;
  height: 2rem;
  padding: 8px;
  border-radius: 4px;
`;
export const NewClientButton = styled(Button)`
  margin-top: 1rem;
  color: #fff;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 40rem;

  @media (max-width: 700px) {
    width: 378px;
  }

  h1 {
    font-family: Alegreya;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #7c878d;
    margin-bottom: 1rem;
  }
`;
