import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import {
  Container,
  ClosedCard,
  Grid,
  RightDetails,
  LeftDetails,
} from "./styles";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import {
  paymentKindTranslated,
  paymentStatusTranslated,
} from "../../../utils/formatSpanShadow";

const ClientDataCard = ({
  isOpen,
  onClick,
  payment,
  demand,
  contract,
  client,
  createdAt,
  ...rest
}) => {
  const parseDate = (date) => {
    if (date) {
      const formattedDate = format(
        new Date(parseISO(date)),
        "dd 'de' MMMM, yyyy HH:mm:ss",
        {
          locale: ptBR,
        }
      );
      return formattedDate;
    }
    return null
  };

  var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return (
    <Container isOpen={isOpen} {...rest}>
      <ClosedCard isOpen={isOpen}>
        <h1>Dados Completos</h1>
        {isOpen ? <FiChevronUp
          color="#7C878D"
          size={24}
          onClick={() => onClick(!isOpen)}
        /> : <FiChevronDown
          color="#7C878D"
          size={24}
          onClick={() => onClick(!isOpen)}
        />}
      </ClosedCard>

      {isOpen ? (
        <Grid>
          <LeftDetails>
            <div>
              <h1>E-mail</h1>
              <h2>{contract?.email ? contract?.email : client?.email}</h2>
            </div>
            <div>
              <h1>Telefone</h1>
              <h2>{contract?.phone}</h2>
            </div>
            <div>
              <h1>CPF</h1>
              <h2>{contract?.cpf}</h2>
            </div>
            <div>
              <h1>Endereço</h1>
              <h2>
                {contract?.address?.address
                  ? `${contract?.address?.address}, `
                  : null}
                {contract?.address?.address_number}{" "}
                {contract?.address?.address_complement
                  ? contract?.address?.address_complement
                  : null}
                <br />
                {contract?.address?.address_neighborhood
                  ? `${contract?.address?.address_neighborhood} - `
                  : null}
                {contract?.address?.address_city
                  ? `${contract?.address?.address_city} - `
                  : null}
                {contract?.address?.address_state
                  ? `${contract?.address?.address_state} • `
                  : null}
                {contract?.address?.address_cep}
              </h2>
            </div>
          </LeftDetails>
          <RightDetails>
            <div>
              <h1>Demanda</h1>
              <h2>{demand?.title && demand?.title}{demand?.title && demand?.code ? ` - ` : null}{demand?.code}</h2>
            </div>
            <div>
              <h1>Data de Registro</h1>
              <h2>{parseDate(createdAt)} </h2>
            </div>
            <div>
              <h1>Geração de Contrato</h1>
              <h2>{parseDate(contract?.created_at)} </h2>
            </div>
            <div>
              <h1>Assinatura do contrato</h1>
              <h2>{parseDate(contract?.updated_at)} </h2>
            </div>
            <div>
              <h1>Data de Pagamento</h1>
              <h2>{parseDate(payment?.updated_at)} </h2>
            </div>
            <div>
              <h1>Pagamento</h1>
              <h2>
                {payment?.kind
                  ? `${paymentKindTranslated[payment?.kind]
                  } • ${payment?.kind === 'card' ?
                    `${payment.installments !== 1 ? `${payment.installments} x ${formatter.format(payment?.amount)}` : `${formatter.format(payment?.amount)}`}`
                    : `${formatter.format(payment?.amount)}`} • ${paymentStatusTranslated[payment?.status]
                  }`
                  : "-"}
              </h2>
            </div>
          </RightDetails>
        </Grid>
      ) : null}
    </Container>
  );
};

export default ClientDataCard;
