import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { updateClientRequest } from "../../../store/modules/clientReview/actions";
import { changeVisibleDrawer } from "../../../store/modules/drawer/actions";
import { FiX, FiCheck } from "react-icons/fi";
import { Container, Input } from "./styles";
import { documentStatusTranslated } from "../../../utils/formatSpanShadow";
import { useQueryClient } from "react-query";

const ItemDocHistory = ({
  status,
  date,
  children,
  revert,
  userInfo,
  options,
}) => {
  const document = useSelector((state) => state.drawer.document);
  const [revertInput, setRevertInput] = useState("");
  const [showRevertInput, setShowRevertInput] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  async function handleRevert(justification) {
    try {
      if (status === "aproved") {
        await api.put(`/api/v1/documents/${document.id}`, {
          status: "reproved",
          justification,
          reverse_status: true,
        });
        dispatch(updateClientRequest(userInfo?.id));
        dispatch(changeVisibleDrawer());
        queryClient.invalidateQueries('getCauseToAnalize');
      } else {
        await api.put(`/api/v1/documents/${document.id}`, {
          status: "aproved",
          reverse_status: true,
          justification,
          month: options?.month,
          year: options?.year,
        });
        dispatch(updateClientRequest(userInfo?.id));
        dispatch(changeVisibleDrawer());
        queryClient.invalidateQueries('getCauseToAnalize');

      }
    } catch (err) {
      err?.response?.data?.document_not_found?.forEach((error) =>
        toast.error(error)
      );
    }
  }

  return (
    <Container
      active={
        options.status !== "analyze"
          ? options.type === "paycheck"
            ? options.month && options.year
            : true
          : false
      }
    >
      <div>
        <label>{documentStatusTranslated[status]}</label>
        <p>{date}</p>
        {revert && !showRevertInput ? (
          options.status !== "analyse" ? (
            <span
              onClick={() => {
                status === "aproved"
                  ? setShowRevertInput(!showRevertInput)
                  : handleRevert();
              }}
            >
              Reverter {documentStatusTranslated[status]}
            </span>
          ) : (
            <span
              onClick={() => { }}
            >
              Reverter {documentStatusTranslated[status]}
            </span>
          )
        ) : null}
        {showRevertInput ? (
          <>
            <Input
              placeholder="Escreva sua justificativa"
              type="text"
              onChange={(event) => setRevertInput(event.target.value)}
            />
            <FiX
              color="#a6042b"
              size={30}
              onClick={() => setShowRevertInput(!showRevertInput)}
            />
            <FiCheck
              color="#54ad1d"
              size={30}
              onClick={() => handleRevert(revertInput)}
            />
          </>
        ) : null}
      </div>
      <p>{children}</p>
    </Container>
  );
};

export default ItemDocHistory;
