import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  > h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    color: #374954;

    span {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 120%;
      color: #7c878d;
    }
  }
`;
