import { all } from "redux-saga/effects";

import user from "./user/saga";
import auth from "./auth/saga";
import clientReview from "./clientReview/saga";
import dashboard from "./causes/saga";
import drawer from "./drawer/saga";
import clients from "./clients/saga";

export default function* rootSaga() {
  return yield all([user, auth, clientReview, dashboard, drawer, clients]);
}
