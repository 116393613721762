import { Container, Today } from "./styles";
import { IssuesMock } from "../issueMock";
import IssueCard from "./components/IssueCard";
interface IssueListProps {
  clientId: string;
}

const IssuesList = ({ clientId }: IssueListProps) => {
  return (
    <Container>
      <Today>Hoje</Today>
      {IssuesMock.map((issue) => (
        <IssueCard issue={issue} />
      ))}
    </Container>
  );
};

export default IssuesList;
