import styled from "styled-components";
import ColoredSpan from "../../../../components/ColoredSpan";
import { Column as PrimeColunm } from "primereact/column";

export const Container = styled.div`
  .p-datatable .p-sortable-column {
    background: #eaedef;
  }

  .p-datatable {
    min-width: 1000px;
  }

  .p-datatable .p-paginator-bottom {
    background: transparent;
    border-width: 0;
    justify-content: space-between;
    margin-left: auto;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: #eaedef;
    white-space: nowrap;

    @media (max-width: 1400px) {
      font-size: 0.8rem;
    }

    @media (max-width: 1080px) {
      font-size: 0.7rem;
    }
  }

  .p-datatable .p-datatable-tbody tr {
    p {
      color: var(--primary);
      visibility: hidden;
    }

    h4 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 140%;

      @media (max-width: 1400px) {
        font-size: 0.8rem;
      }

      @media (max-width: 1080px) {
        font-size: 0.7rem;
      }
    }
    &:hover {
      background: #f7f7f7;
      cursor: pointer;
      p {
        visibility: visible;
        text-decoration-line: underline;
      }

      h4 {
        color: var(--primary);
        text-decoration-line: underline;
      }
    }
  }

  .p-datatable .p-datatable-tbody tr td div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-datatable .p-paginator-bottom {
    background: transparent;
    border-width: 0;
    justify-content: space-between;
    margin-left: auto;
  }

  .p-paginator {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Span = styled(ColoredSpan)`
  @media (max-width: 1400px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1080px) {
    font-size: 0.7rem;
  }
`;

export const DateText = styled.span`
  @media (max-width: 1400px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1080px) {
    font-size: 0.7rem;
  }
`;

export const TableIconsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
`;

export const Tooltip = styled.div`
  position: relative;
  span {
    background: #212b31;
    min-width: 300px;
    padding: 8px;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 12px);
    left: 30%;
    transform: translateX(-50%);
    color: #fff;
    white-space: pre-line;
    &::before {
      content: "";
      border-style: solid;
      border-color: #212b31 transparent;
      border-width: 6px 6px 0 6px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &:hover span {
    visibility: ${({ active }) => (active ? "visible" : "none")};
    opacity: 1;
  }
`;

export const Column = styled(PrimeColunm)`
  @media (max-width: 1400px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1080px) {
    font-size: 0.7rem;
  }
`;

export const AnalisarColumn = styled(PrimeColunm)``;

export const AnalisarDiv = styled.div`
  display: flex;
  min-width: 50px;

  .p-splitbutton {
    transition: all 0.3s;
    &:hover {
      filter: brightness(0.8);
    }

    .p-splitbutton-defaultbutton {
      width: 4rem;

      span {
        font-size: 1rem;

        &:hover{ 
          color: var(--gray-500);
        }
      }
    }
  

    >button {
      background: var(--gray-200);
      border-color: #eee;
      outline: none;
      color: var(--gray-500);

      &:hover {
        background: var(--gray-200);
        border-color: var(--primary);
      }
    }
  }
`;
