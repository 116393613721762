import styled from "styled-components";

type DivProps = {
  selected?: boolean;
};

export const Container = styled.div<DivProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: ${({ selected }) => (selected ? "#e7004c" : "#fff ")};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: ${({ selected }) => (selected ? "#fff" : "#374954")};
  border: 1px solid #eaedef;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    color: ${({ selected }) => (selected ? "#fff" : "#e7004c")};
    border: 1px solid #e7004c;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }
`;
