import { Dialog } from "primereact/dialog";

import { Container, Row, ButtonsBox, Button } from "./styles";
import { FiAlertTriangle } from "react-icons/fi";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteClientRequest } from "../../store/modules/clients/actions";

const DeleteClientDialog = ({ isOpen, closeModal, client }: any) => {
  const [checkboxIsChecked, setCheckboxIsChecked] = useState<any>();
  const dispatch = useDispatch();

  return (
    <Dialog
      header="Confirmação de Delete"
      visible={isOpen}
      onHide={() => closeModal(false)}
      maximizable={false}
    >
      <Container>
        <Row>
          <FiAlertTriangle size={24} color="var(--primary)" />
          <span>Você tem certeza que deseja deletar esse cliente?</span>
        </Row>
        <Row style={{ marginTop: "1rem" }}>
          <h3>Nome:</h3> <p>{client.name}</p>
        </Row>
        <Row>
          <h3>E-mail:</h3> <p>{client.email}</p>
        </Row>

        <Row style={{ marginTop: "2rem" }}>
          <input
            type="checkbox"
            value={checkboxIsChecked}
            onChange={(e) => setCheckboxIsChecked(e.target.checked)}
          />
          <p> Confirmar exclusão do cliente</p>
        </Row>

        <ButtonsBox>
          <Button
            disabled={!checkboxIsChecked}
            onClick={() => {
              if (checkboxIsChecked) {
                dispatch(deleteClientRequest(client));
                setCheckboxIsChecked(false);
                closeModal(false);
              }
            }}
            style={{ color: checkboxIsChecked ? "#fff" : "#312e38" }}
          >
            Deletar
          </Button>
          <Button
            onClick={() => {
              setCheckboxIsChecked(false);
              closeModal(false);
            }}
            style={{ color: "#fff" }}
          >
            Cancelar
          </Button>
        </ButtonsBox>
      </Container>
    </Dialog>
  );
};

export default DeleteClientDialog;
