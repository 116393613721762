import { toast } from "react-toastify";
import { takeLatest, put, all, select } from "redux-saga/effects";
import api from "../../../services/api";
import { signOut } from "../auth/actions";
import {
  requestNewAnalaizeSuccess,
  changeVisibleDrawer,
  refreshAnalyzeList,
} from "./actions";
import { getDrawerDocumentsInAnalyze } from "./selector";

export function* findDocument({ payload }) {
  try {
    const { document, clientId } = payload;
    if (document) {
      yield put(requestNewAnalaizeSuccess(document));
      yield put(changeVisibleDrawer());
    } else if (clientId) {
      const { data } = yield api.get(`/api/v1/clients/${clientId}`);
      yield put(requestNewAnalaizeSuccess(data?.data));
      yield put(changeVisibleDrawer());
    } else {
      const hasUnckechedDocument = payload.find(
        (item) => item?.document?.status === "analyze"
      );
      yield put(requestNewAnalaizeSuccess(hasUnckechedDocument));
      yield put(changeVisibleDrawer());
    }
  } catch (error) {
    if (error.response.status === 401) yield put(signOut());
    toast.error("Não foi encontrado nenhum documento");
  }
}

export function* removeFristDocumentToAnalyze() {
  let hasDocumentToAnalayze = yield select(getDrawerDocumentsInAnalyze);
  const firstElementOfDocumentsToAnalyzeList = [...hasDocumentToAnalayze];
  try {
    firstElementOfDocumentsToAnalyzeList.shift();
    yield put(
      requestNewAnalaizeSuccess(firstElementOfDocumentsToAnalyzeList[0])
    );
    yield put(refreshAnalyzeList(firstElementOfDocumentsToAnalyzeList));
  } catch (error) {
    if (error.response.status === 401) yield put(signOut());
    yield put(changeVisibleDrawer());
    toast.success("Todos os documentos foram analisados!");
  }
}

export default all([
  takeLatest("@drawer/REQUEST_NEW_ANALIZE", findDocument),
  takeLatest("@drawer/ANALYZE_NEXT", removeFristDocumentToAnalyze),
]);
