import React, { useState } from "react";
import {
  Container,
  Content,
  Profile,
  Menu,
  SidebarCotent,
  PagesDiv,
  StagingText,
} from "./styles";
import logoImg from "../../assets/logo.svg";
import { FiLogOut } from "react-icons/fi";
import history from "../../services/history";
import { Sidebar } from "primereact/sidebar";
import { signOut } from "../../store/modules/auth/actions";
import { useDispatch } from "react-redux";

const Topbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { name } = JSON.parse(localStorage.getItem("@biro:user"));
  const toggle = () => setIsMenuOpen(!isMenuOpen);
  const dispatch = useDispatch();

  const pages = [
    {
      id: 1,
      label: "Clientes",
      path: "/clients",
    },
    {
      id: 2,
      label: "Causas",
      path: "/causes",
    },
    // {
    //   id: 3,
    //   label: "Analise",
    //   path: "/causereview",
    // },
  ];


  return (
    <Container>
      <Content>
        <Sidebar
          showCloseIcon={false}
          visible={isMenuOpen}
          position="left"
          onHide={() => setIsMenuOpen(false)}
        >
          <SidebarCotent>
            <img src={logoImg} alt="logo" />
            <PagesDiv>
              {pages.map((page) => (
                <div
                  key={page.id}
                  onClick={() => {
                    history.push(page.path);
                    setIsMenuOpen(false);
                  }}
                >
                  <span key={page.id}>{page.label}</span>
                </div>
              ))}
            </PagesDiv>
            <FiLogOut
              size={24}
              color="#e7004c"
              onClick={() => dispatch(signOut())}
              style={{ width: 36, height: 36 }}
            />
          </SidebarCotent>
        </Sidebar>
        <Menu>
          <img src={logoImg} alt="Birô" onClick={() => history.push("/")} />
          <div onClick={() => toggle()}>
            <i className="pi pi-align-justify p-mr-2" />
            <span>menu</span>
          </div>
          {process.env.REACT_APP_ENVIRONMENT === 'staging' ? <StagingText>Staging</StagingText> : null}
        </Menu>
        <Profile>
          <span>{name}</span>
          {/* <img src={avatar} alt="Avatar" /> */}
        </Profile>
      </Content>
    </Container>
  );
};

export default Topbar;
