import styled from "styled-components";
import DataTable from "./DataTable";
import Progress from "./DocumentProgress";
import CustomSpan from "../../components/ColoredSpan";
import AppDrawer from "../../components/Drawer";

export const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > header {
    margin-top: 15px;
    display: flex;
    width: 80vw;

    > div {
      border-radius: 4px;
      cursor: pointer;
      padding: 2px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: background 0.2s ease-in-out;
      &:hover {
        filter: brightness(0.9);
        background: #d8d8d8;
      }

      h1 {
        margin-left: 5px;
        color: var(--primary);
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.05em;
      }
    }
  }
`;

export const Drawer = styled(AppDrawer)`
  width: 80vw;
  height: 80vh;
  padding: 0;
  margin: 5% 0 0 10%;

  .p-sidebar-icons {
    display: none;
  }
`;

export const ClientPreview = styled.div`
  width: 80vw;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #7c878d;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-right: 24px;
    line-height: 140%;
    color: #7c878d;
  }
`;

export const ColoredSpan = styled(CustomSpan)`
  margin-right: 37px;
`;

export const Table = styled(DataTable)``;

export const DocumentReview = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  width: 80vw;
  margin-top: 30px;
`;

export const NextClientButton = styled.div`
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #374954;
  background: #ffffff;
  border: 1px solid #eaedef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 16px;
  cursor: pointer;

  &:hover {
    color: var(--primary);
    border: 1px solid var(--primary);

    svg {
      color: var(--primary);
    }
  }

  svg {
    margin-left: 8px;
    color: #7c878d;
  }
`;

export const StartAnaliseButton = styled.button`
  background: #e7004c;
  height: 60px;
  width: 100%;
  border: 0;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #ffffff;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const DocumentProgress = styled(Progress)`
  margin-top: 16px;
`;

export const AnaliseDiv = styled.div`
  padding: 0 0 0 30px;
`;

export const SendDocToMailButton = styled.button`
  background: #1889c7;
  margin-top: 0.5rem;
  height: 60px;
  width: 100%;
  border: 0;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #ffffff;

  &:hover {
    filter: brightness(0.8);
  }
`;
