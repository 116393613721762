import React, { useState, useEffect } from "react";
import { Container, Main, PaginationDiv, Drawer } from "./styles";
import DataTable from "./components/Datatable";
import { useDispatch, useSelector } from "react-redux";
import StatusFilters from "./components/StatusFilters";
import Paginator from "./components/Paginator";
import PageRows from "./components/PageRows";
import { updateTableRequest } from "../../store/modules/clients/actions";
import history from "../../services/history";
import { useDebounce } from "../../hooks/useDebounce";

const Clients: React.FC = () => {
  const tableOptions = useSelector((state: any) => state.clients.table.options);
  const [searchInput, setSearchInput] = useState(tableOptions?.email_or_name);
  const searchDebounced = useDebounce(searchInput, 600);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateTableRequest({
        page: 1,
        status: tableOptions.status,
        itemsQnt: tableOptions.itemsQnt,
        order: tableOptions.order,
        sort: tableOptions.sort,
        email_or_name: searchDebounced,
      })
    );
  }, [
    dispatch,
    searchDebounced,
    tableOptions.itemsQnt,
    tableOptions.order,
    tableOptions.sort,
    tableOptions.status,
  ]);

  function handleFilterOnChange(status: string) {
    dispatch(
      updateTableRequest({
        page: 1,
        status: status,
        itemsQnt: tableOptions.itemsQnt,
        order: tableOptions.order,
        sort: tableOptions.sort,
        email_or_name: searchDebounced,
      })
    );
  }

  function handleOnSortChange(
    sort: "" | "name" | "email" | "create_at" | "status" | undefined
  ) {
    dispatch(
      updateTableRequest({
        page: 1,
        status: tableOptions.status,
        itemsQnt: tableOptions.itemsQnt,
        order:
          sort === tableOptions.sort
            ? tableOptions.order === "asc"
              ? "desc"
              : "asc"
            : "asc",
        sort: sort,
        email_or_name: searchDebounced,
      })
    );
  }

  var filters = [
    { step: "", status: "", label: "Todos" },
    {
      status: "confirmed",
      label: "Confirmado",
    },
    {
      status: "analyze",
      label: "Em Analise",
    },
    {
      status: "pending",
      label: "Pendente",
    },
    {
      status: "reproved",
      label: "Reprovado",
    }, /// referentes a documentação
  ];

  return (
    <Container>
      <Drawer />
      <header>
        <h1>Clientes</h1>
        <div>
          <input
            placeholder="Buscar"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <i className="pi pi-search p-mr-2" />
        </div>
      </header>
      <StatusFilters
        filters={filters}
        onChange={handleFilterOnChange}
        nagivationToNewItem={() => history.push("clients/new")}
      />
      <Main>
        <DataTable handleOnSortChange={handleOnSortChange} />
        <PaginationDiv>
          <PageRows />
          <Paginator />
        </PaginationDiv>
      </Main>
    </Container>
  );
};

export default Clients;
