import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  background: #eaedef;
  min-width: 900px;

  header {
    width: 100%;
    height: 56px;
    min-width: 120px;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 24px;
    background: #ffffff;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-weight: bold;
        font-size: 16px;
        line-height: 140%;
        color: #374954;
      }

      svg {
        cursor: pointer;
        margin-left: 5px;
      }
    }

    > svg {
      cursor: pointer;
      transition: background 0.2s ease-in;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      padding: 4px;


      &:hover {
        background: #d8d8d8;
      }
    }
  }

  footer {
    background: #fff;
    min-width: 900px;
    position: sticky;
    bottom: 0;
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 24px;
    width: 100%;

    border-top: 1px solid #d8d8d8;
  }
`;

export const ImageAnalise = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
  }
  ::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 4px;
    background-color: var(--primary);
  }

  .product-item .product-item-content {
    border: 1px solid var(--surface-d);
    border-radius: 3px;
    margin: 0.3rem;
    text-align: center;
    padding: 2rem 0;
  }

  .product-item .product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`;

export const ImageBox = styled.div`
  display: flex;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  embed {
    z-index: 0;
    min-height: 100%;
    width: 100%;
  }

  img {
    display: flex;
    width: 100%;
    height: 65vh;
  }
`;

export const DeclinedContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
  /* margin-top: 50px; */
  padding: 32px 24px;
  z-index: 1;

  > label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 130%;
    color: #7c878d;
  }

  > p {
    margin-top: 1rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 140%;
    color: #374954;
  }

  > textarea {
    margin-top: 0.5rem;
    width: 100%;
    padding: 0px;
    height: 3.125rem;
    background: #f4f5f5;
    border: 1px solid #aab6bc;
    box-sizing: border-box;
    border-radius: 4.8px;
    padding: 0.5rem 1rem;

    &::placeholder {
      font-size: 0.875rem;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
    }
  }

  > span {
    margin-top: 0.25rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
  }
`;

export const DeclinedButtonDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;

    > span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      text-decoration-line: underline;
      color: #e7004c;
      cursor: pointer;
      transition: filter 0.2s;
      &:hover {
        filter: brightness(0.6);
      }
    }
  }
`;

export const HistoryContent = styled.div`
  background: #fff;
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 1.5rem;
  flex-direction: column;

  > div {
    margin-top: 1.5rem;
    width: 100%;
  }
`;

export const ContentTitle = styled.label`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;
  color: #7c878d;
`;

export const Button = styled.div`
  height: 64px;
  width: 280px;
  background: ${({ disabled }) => (disabled ? "#dddddd" : "#54ad1d")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${({ disabled }) => (disabled ? "#fff" : "#fff")};
  text-transform: uppercase;
  transition: filter 0.15s;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  @media (max-width: 1400px) {
    width: 15.625rem;
    font-size: 1rem;
    padding: 10px 2px;
  }
     
  @media (max-width: 1080px) {
    width: 8rem;
    font-size: .75rem;
    padding: 10px 2px;
  }

  &:hover {
    filter: brightness(0.8);
  }

  
`;

export const DeclineButton = styled.div`
  height: 64px;
  width: 25%;
  background: ${({ disabled }) => (disabled ? "#dddddd" : "#a6042b")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${({ disabled }) => (disabled ? "#fff" : "#fff")};
  text-transform: uppercase;
  transition: filter 0.15s;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

 

  &:hover {
    filter: brightness(0.8);
  }

  svg {
    margin-right: 15px;
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  h6 {
    font-family: Roboto;
    font-size: 12px;
    line-height: 140%;
    color: #7c878d;
    margin-bottom: 4px;
  }

  span {
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    color: #374954;
  }

  > div {
    display: flex;
    flex-direction: row;
    /* margin-left: 25vw; */

    h6 {
      font-size: 16px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

export const ClientFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  min-width: 25rem;
  white-space: nowrap;

  > div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    h6 {
      font-family: Roboto;
      font-size: 0.75rem;
      line-height: 140%;
      color: #7c878d;
      margin-bottom: 4px;

      @media (max-width: 1400px) {
        font-size: 0.50rem;
      }

      @media (max-width: 1080px) {
        font-size: 0.25rem;
      }
    }

    span {
      font-family: Roboto;
      font-weight: bold;
      font-size: 0.8rem;
      line-height: 140%;
      color: #374954;

      @media (max-width: 1400px) {
        font-size: 0.7rem;
      }

    }

    p {
      font-family: Roboto, sans-serif;
      font-weight: normal;
      color: #7c878d;

      @media (max-width: 1400px) {
        font-size: 0.75rem;
      }

      @media (max-width: 1080px) {
        font-size: 0.75rem;
      }
    }

    margin-left: 1.875rem;

    border-left: 1px solid #7c878d;

    @media (max-width: 1400px) {
      margin-left: 0.5rem;
    }

    > p {
      margin-left: 1.875rem;
      @media (max-width: 1400px) {
        margin-left: 0.5rem;
      }
    
    }

    &:first-child {
      margin-left: 0;
      border-left: 0;

      > p {
        margin-left: 0;
      }
    }

    &:last-child {
      border-left: 0;
      margin-left: 0;
      margin-right: 5px;
    }
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40vw;
  min-height: 50vh;
  background: green;
`;

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid #374954;
  height: 18px;
  margin-left: 4px;
`;

export const FooterSelectBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.875rem;

  .primeSelect {
    margin-left: 0.313rem;
    display: flex;
    justify-content: center;
    margin-top: 3px;
    div {
      height: 1rem;
      width: auto;
    }
    .p-button.p-highlight {
      background: #f16694;
      border-color: #f16694;
      color: #fff;
    }

    .p-button {
      &:focus {
        box-shadow: 0 0 0 0.1rem var(--primary);
      }
    }
  }
`;
