import React from "react";
import { DataTable } from "primereact/datatable";
import { parseISO } from "date-fns";
import { Column } from "primereact/column";
import { Container, Span, TableIconsBox, Tooltip } from "./styles";
import {
  documentStatusColors,
  documentStatusTranslated,
} from "../../../utils/formatSpanShadow";
import { FiFileText } from "react-icons/fi";
import { BiMessageRoundedDetail } from "react-icons/bi";
import {
  changeDrawerType,
  requestNewAnalaize,
  startAnalize,
} from "../../../store/modules/drawer/actions";
import { useDispatch } from "react-redux";

const Table = ({ data, ...rest }) => {
  const dispatch = useDispatch();

  function parseDate(stringDate, time) {
    if (stringDate) {
      if (time) {
        const newDate = new Intl.DateTimeFormat("pt-BR", {
          dateStyle: "short",
          timeStyle: "short",
        }).format(parseISO(stringDate));
        return newDate;
      }
      const newDate = new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
      }).format(parseISO(stringDate));
      return newDate;
    }
    return null;
  }

  const nameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <h4>{rowData?.field_name}</h4>
      </React.Fragment>
    );
  };

  const cadastroDateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {rowData?.document?.created_at
            ? parseDate(rowData?.document?.created_at)
            : "-"}
        </span>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Span color={documentStatusColors[rowData?.document?.status]}>
          {rowData?.document?.status
            ? documentStatusTranslated[rowData?.document?.status]
            : "-"}
        </Span>
      </React.Fragment>
    );
  };

  function handleRowClick(rowEvent) {
    const { data } = rowEvent;
    dispatch(startAnalize(''));
    dispatch(changeDrawerType("cause"));
    dispatch(requestNewAnalaize({ document: data }));
  }

  function handleTooltipHistory(historyData) {
    if (historyData) {
      const tooltipString = historyData?.map((item, index) => {
        return `${index + 1}ª envio: ${parseDate(
          item.history.created_at,
          true
        )} • ${documentStatusTranslated[item.history.status]}${item.history.status !== "aproved"
          ? `: ${item.history.justification}`
          : ""
          }`;
      });
      return tooltipString.join("\r\n");
    }
    return null;
  }

  const IconsBodyTemplate = (rowData) => {
    return (
      <TableIconsBox>
        {rowData?.document?.checked ? <span>Check</span> : null}
        <Tooltip active={rowData?.document_histories.length !== 0}>
          {<span>{handleTooltipHistory(rowData?.document_histories)}</span>}
          <BiMessageRoundedDetail
            color={
              rowData?.document_histories.length !== 0 ? "#e7004c" : "#7c878d"
            }
            size={24}
            style={{ marginRight: "16px" }}
          />
        </Tooltip>
        <FiFileText
          color={rowData?.document !== null ? "#e7004c" : "#7c878d"}
          size={24}
        />
      </TableIconsBox>
    );
  };

  return (
    <Container>
      <div className="card">
        <DataTable
          value={data}
          onRowClick={handleRowClick}
          sortMode="multiple"
          emptyMessage="Causas não encontradas"
        >
          <Column field="name" header="Nome" body={nameBodyTemplate} sortable />
          <Column
            field="cadastroDate"
            header="Data Cadastro"
            body={cadastroDateBodyTemplate}
            sortable
          />
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            sortable
          />
          <Column
            field="Icons"
            header="" // Coluna vazia, só aparece se passar o mouse por cima
            body={IconsBodyTemplate}
            style={{ width: "250px" }}
          />
        </DataTable>
      </div>
    </Container>
  );
};

export default Table;
