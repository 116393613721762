import { useSelector } from "react-redux";
import { Container, Group, Item, Dropdown, NewUserButton } from "./styles";

const StatusFilters = ({ filters, onChange, hasOthers = false, nagivationToNewItem }) => {
  const tableOptions = useSelector((state) => state.dashboard.table.options)

  function handleFilterSelected(status, step) {
    return onChange(status, step);
  }

  const outros = [
    {
      step: "contract",
      status: "pending_generation",
      name: "Contrato Pendente Geração",
    },
    {
      step: "contract",
      status: "pending_subscription",
      name: "Contrato Pendente Assinatura",
    },
    { step: "payment", status: "pending", name: "Pagamento Pendente" },
    { step: "execution", status: "progress", name: "Execução em Andamento" },
  ];

  const onSelectChange = (e) => {
    return onChange(e.value.status, e.value.step);
  };

  return (
    <Container>
      <Group>
        {filters?.map((filter) => (
          <Item
            key={filter.status}
            selected={tableOptions.status === filter.status}
            onClick={() => handleFilterSelected(filter.status, filter.step)}
          >
            {filter?.label}
          </Item>
        ))}
        {hasOthers ? (
          <Dropdown
            options={outros}
            onChange={onSelectChange}
            optionLabel="name"
            placeholder="Outros"
            style={{ marginLeft: "8px" }}
            selected={outros.find((item) => item.status === tableOptions.status && item.step === tableOptions.step)}
          />
        ) : null}
      </Group>
      <Group>
        {nagivationToNewItem ?
          <NewUserButton onClick={nagivationToNewItem}>Novo Cliente</NewUserButton>
          : null
        }
        <Item>Recentes</Item>
      </Group>
    </Container>
  );
};

export default StatusFilters;
