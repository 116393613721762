export enum PaymentKind {
  CREDIT_CARD = "card",
  BOLETO = "boleto",
}

export enum PaymentStatus {
  WAITING = "waiting",
  CONFIRMED = "confirmed",
  ERROR = "error",
}

export type Payment = {
  kind: PaymentKind;
  id: number;
  amount: string;
  due_date: string;
  boleto_barcode: string;
  link: string;
  status: PaymentStatus;
  honorary: number;
  discount: number;
  finalValue: number;
};
