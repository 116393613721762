import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
`;

export const Content = styled.div`
  background: #ffffff;
  height: 100%;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    margin-right: 16px;
    white-space: nowrap;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #374954;
  }

  > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: contain;
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    cursor: pointer;
  }

  > div {
    cursor: pointer;
    margin-left: 15px;
    display: flex;
    border-radius: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
    transition: background 0.2 ease-in-out;
    &:hover {
      background: #d8d8d8;
    }

    > span {
      margin-left: 5px;
      white-space: nowrap;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #374954;
    }

    > i {
      font-size: 24px;
      color: #374954;
    }
  }
`;

export const SidebarCotent = styled.div`
  background: #ffff;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  > img {
    color: var(--primary);
    margin-top: 30px;
  }

  > svg {
    cursor: pointer;
    min-height: 24px;
    min-width: 24px;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const PagesDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  font-size: 18px;
  margin-bottom: 150%;

  div {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: box-shadow 0.6s;
    border-radius: 4px;
    span {
    }

    &:hover {
      box-shadow: inset 4.5em 0 0 0 var(--primary);
      cursor: pointer;
      span {
        font-weight: bold;
        /* color: #fff; */
      }
    }
  }
`;

export const StagingText = styled.h1`
  font-family: "Roboto", sans-serif;
  margin-left: 50px;
  color: var(--primary);
  font-size: 36px;
`;
