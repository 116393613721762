import styled from "styled-components";
import ColoredSpan from "../../../components/ColoredSpan";

export const Container = styled.div`
  .p-datatable .p-sortable-column {
    background: #eaedef;
  }
  .p-datatable .p-paginator-bottom {
    background: transparent;
    border-width: 0;
    justify-content: space-between;
    margin-left: auto;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: #eaedef;
  }

  .p-datatable .p-datatable-tbody tr {
    p {
      color: #fff;
    }

    h4 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      align-items: center;
    }
    &:hover {
      background: #f7f7f7;
      cursor: pointer;
      p {
        color: var(--primary);
        text-decoration-line: underline;
      }

      h4 {
        color: var(--primary);
        text-decoration-line: underline;
      }
    }
  }

  .p-datatable .p-paginator-bottom {
    background: transparent;
    border-width: 0;
    justify-content: space-between;
    margin-left: auto;
  }

  .p-paginator {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Span = styled(ColoredSpan)``;

export const TableIconsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  > span {
    margin-right: 50px;
    color: #fff;
    background: var(--primary);
    border-radius: 20px;
    padding: 2px 6px;
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
  }
`;

export const Tooltip = styled.div`
  position: relative;
  span {
    width: 350px;
    background: #212b31;
    padding: 8px;
    word-break: break-all;
    white-space: normal;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 12px);
    left: 30%;
    transform: translateX(-50%);
    color: #fff;
    white-space: pre-line;
    &::before {
      content: "";
      border-style: solid;
      border-color: #212b31 transparent;
      border-width: 6px 6px 0 6px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &:hover span {
    visibility: ${({ active }) => (active ? "visible" : "none")};
    opacity: 1;
  }
`;

