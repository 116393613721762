import React, { useCallback, useRef } from "react";
import { FiMail, FiLock } from "react-icons/fi";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import getValidationErrors from "../../utils/getValidationErrors";

import logoImg from "../../assets/logo.svg";

import Input from "../../components/Input";
import Button from "../../components/Button";

import { Container, Content, AnimationContainer, Background } from "./styles";
import { signInRequest } from "../../store/modules/auth/actions";
import { useDispatch, useSelector } from "react-redux";

interface SignInFormData {
  email: string;
  password: string;
}

interface stateForm {
  auth: {
    loading: boolean;
  };
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const loading = useSelector((state: stateForm) => state.auth.loading);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email("Digite um e-mail válido")
            .required("E-mail obrigatório"),
          password: Yup.string().required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(signInRequest(data?.email, data?.password));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [dispatch]
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Birô" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu logon</h1>

            <Input
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              height="auto"
            />

            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Senha"
              height="auto"
            />

            <Button type="submit" loading={loading}>
              Entrar
            </Button>

            {/* <Link to="forgot">Esqueci minha senha</Link> */}
          </Form>

          {/* <Link to="signup">
            <FiLogIn />
            Criar conta
          </Link> */}
        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
};

export default SignIn;
