import { Client } from "../client";

export type Cause = {
  client: Client;
  id: Number;
  client_id: Number;
  created_at: string;
  informations: {
    deadline: string;
    documents_sent: string;
    validated_documents: string;
    in_analysis: string;
  };
  step: CauseStep;
  status: CauseStatus;
  demand_code: string;
};

export interface CauseTableRowData {
  email: string;
  name: string;
  cadastroDate: string;
  demand: string;
  in_analysis: string;
}

export enum CauseStatus {
  pending = "pending",
  pending_generation = "pending_generation",
  pending_subscription = "pending_subscription",
  analyze = "analyze",
  concluded = "concluded",
}

export enum CauseStep {
  contract = "contract",
  payment = "payment",
  documentation = "documentation",
  execution = "execution",
}
