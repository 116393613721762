export type updateTableProps = {
  page?: number;
  rows?: number;
  step?: string;
  status?: string;
  itemsQnt?: number;
  email_or_name?: string;
  order: "asc" | "desc" | "";
  sort: "name" | "email" | "create_at" | "status" | "step" | "";
};

export function updateTableRequest(payload: updateTableProps) {
  return {
    type: "@dashboard/UPDATE_TABLE_REQUEST",
    payload,
  };
}

export function filterTable(payload: string) {
  return {
    type: "@dashboard/FILTER_TABLE",
    payload,
  };
}

export function tableLoadingTrue() {
  return {
    type: "@dashboard/TABLE_LOADING_SETTRUE",
  };
}

export function tableLoadingFalse() {
  return {
    type: "@dashboard/TABLE_LOADING_SETFALSE",
  };
}

export function updateTableSuccess(payload: any) {
  return {
    type: "@dashboard/UPDATE_TABLE_SUCCESS",
    payload,
  };
}

export function updateTableFailure(payload: any) {
  return {
    type: "@dashboard/UPDATE_TABLE_FAILURE",
    payload,
  };
}

export function sendDocToEmails(payload: any) {
  return {
    type: "@drawer/SEND_EMAILS_REQUEST",
    payload,
  };
}
