import { Issue } from "../../../../../../types/issue";

import { Container, Content, Dot, Title } from "./styles";

interface IssueCardProps {
  issue: Issue;
}

const IssueCard = ({ issue }: IssueCardProps) => {
  return (
    <Container>
      <Content>
        <header>
          <Title>{issue.title}</Title>
          {issue.hasUnReadMessage && <Dot color="var(--yellow-500)" />}
        </header>
        <main>
          <span>Igor Gesso</span>
        </main>
      </Content>
    </Container>
  );
};

export default IssueCard;
