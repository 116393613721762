import { Cause } from "../../types/cause";
import api from "../api";
import { CauseInterface, GetNextCauseId } from "./types";

export const getCauses = async (): Promise<any> => {
  const { data } = await api.get<Cause>(`causes`);
  return data;
};

export const getDocumentsOfCause = async (id: number): Promise<any> => {
  const { data } = await api.get(`causes/${id}/documents`);

  return data;
};

export const getCause = async (id: number): Promise<CauseInterface> => {
  const { data } = await api.get(`/api/v1/causes/${id}`);
  return data;
};

export const getNextCause = async (id: number): Promise<GetNextCauseId> => {
  const { data } = await api.get(`/api/v1/causes/${id}/next`);
  return data;
};

export const CausesApi = {
  getCause,
  getCauses,
  getDocumentsOfCause,
  getNextCause,
};
