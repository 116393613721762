import { useSelector } from "react-redux";
import { Container, Group, Item, NewUserButton } from "./styles";

const StatusFilters = ({ filters, onChange, nagivationToNewItem }) => {
  const tableOptions = useSelector((state) => state.clients.table.options)

  function handleFilterSelected(status, step) {
    return onChange(status, step);
  }


  return (
    <Container>
      <Group>
        {filters?.map((filter) => (
          <Item
            key={filter.status}
            selected={tableOptions.status === filter.status}
            onClick={() => handleFilterSelected(filter.status, filter.step)}
          >
            {filter?.label}
          </Item>
        ))}
      </Group>
      <Group>
        {nagivationToNewItem ?
          <NewUserButton onClick={nagivationToNewItem}>Novo Cliente</NewUserButton>
          : null
        }
        <Item>Recentes</Item>
      </Group>
    </Container>
  );
};

export default StatusFilters;
