import { takeLatest, put, all } from "redux-saga/effects";
// import { toast } from 'react-toastify';
import { updateProfileFailure } from "./actions";

export function* updateProfile({ payload }) {
  try {
  } catch (err) {
    // toast.error('Erro ao atualizar o perfil, confira seus dados');
    yield put(updateProfileFailure());
  }
}

export default all([takeLatest("@user/UPDATE_PROFILE_REQUEST", updateProfile)]);
