import { combineReducers } from "redux";
import user from "./user/reducer";
import auth from "./auth/reducer";
import clientReview from "./clientReview/reducer";
import dashboard from "./causes/reducer";
import drawer from "./drawer/reducer";
import clients from "./clients/reducer";

export default combineReducers({
  user,
  auth,
  clientReview,
  dashboard,
  drawer,
  clients,
});
