import styled from "styled-components";
import ColoredSpan from "../../../components/ColoredSpan";

export const Container = styled.div`
  .p-datatable .p-sortable-column {
    background: #eaedef;
  }
  .p-datatable .p-paginator-bottom {
    background: transparent;
    border-width: 0;
    justify-content: space-between;
    margin-left: auto;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: #eaedef;
  }

  .p-datatable .p-datatable-tbody tr {
    p {
      color: var(--primary);
      visibility: hidden;
    }

    h4 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 140%;
      display: flex;
      align-items: center;
      @media (max-width: 1080px) {
        font-size: 14px;
      }

      @media (max-width: 820px) {
        font-size: 12px;
      }
    }

    > td span {
      @media (max-width: 1080px) {
        font-size: 14px;
      }

      @media (max-width: 820px) {
        font-size: 12px;
      }
    }

    &:hover {
      background: #f7f7f7;
      cursor: pointer;
      p {
        visibility: visible;
        text-decoration-line: underline;
      }

      h4 {
        color: var(--primary);
        text-decoration-line: underline;
      }
    }
  }

  .p-datatable .p-datatable-tbody tr td {
    text-align: center;
  }

  .p-paginator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
`;

type PageButtonProps = {
  active?: boolean;
};
export const PageButton = styled.button<PageButtonProps>`
  width: 40px;
  height: 40px;
  background: ${({ active }) => (active ? `#e7004c` : "#fff")};
  color: ${({ active }) => (active ? `#ffff` : "#374954")};
  border: 1px solid #eaedef;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 8px;

  &:hover {
    border: 1px solid #e7004c;
    /* color: ${({ active }) => (active ? `#fff` : "#e7004c")}; */
  }
`;

export const Span = styled(ColoredSpan)``;
