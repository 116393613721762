import React from "react";
import { Switch } from "react-router-dom";
import Clients from "../pages/Clients";
import Causes from "../pages/Causes";
// import CausesReview from "../pages/CausesReview";
import CauseReview from "../pages/CauseReview";
import SignIn from "../pages/SignIn";
// import SignUp from "../pages/SignUp";
import Route from "./Route";
import NewClient from "../pages/Clients/NewClient";
import Chat from "../pages/Chat";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    {/* <Route path="/signup" exact component={SignUp} /> */}
    <Route path="/clients" exact component={Clients} isPrivate />
    <Route path="/clients/new" exact component={NewClient} isPrivate />
    <Route path="/client/:id/issues/" exact component={Chat} isPrivate />
    <Route path="/causes" exact component={Causes} isPrivate />
    <Route path="/causereview" exact component={CauseReview} isPrivate />
  </Switch>
);
export default Routes;
