import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import history from "../../../../services/history";
import { parseISO } from "date-fns";
import {
  Container,
  Span,
  TableIconsBox,
  Tooltip,
  Column,
  DateText,
  AnalisarColumn,
  AnalisarDiv,
} from "./styles";
import {
  clientStatusColors,
  statusTranslated,
} from "../../../../utils/formatSpanShadow";
import { useDispatch, useSelector } from "react-redux";
import {
  requestNewAnalaize,
  changeDrawerType,
  startAnalize,
} from "../../../../store/modules/drawer/actions";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { SplitButton } from "primereact/splitbutton";
import DeleteClientDialog from "../../../../components/DeleteClientDialog";

const Table = ({ handleOnSortChange }) => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients.table.data);
  const loading = useSelector((state) => state.clients.table.loading);
  const [selectedRow, setSelectedRow] = useState("");
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  function parseDate(stringDate, time) {
    if (stringDate) {
      if (time) {
        const newDate = new Intl.DateTimeFormat("pt-BR", {
          dateStyle: "short",
          timeStyle: "short",
        }).format(parseISO(stringDate));
        return newDate;
      }
      const newDate = new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
      }).format(parseISO(stringDate));
      return newDate;
    }
    return null;
  }

  function findCellphoneWithRowdata(data) {
    const { phone, clientIdent } = data;
    if (phone) {
      return phone;
    }
    if (clientIdent?.phone) {
      return clientIdent.phone;
    }
    return "-";
  }

  function findMailWithRowdata(data) {
    const { email, clientIdent } = data;
    if (email) {
      return email;
    }
    if (clientIdent) {
      return clientIdent.email;
    }
    return "-";
  }

  const nameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <h4>{rowData?.name}</h4>
      </React.Fragment>
    );
  };

  const emailBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <h4>{String(findMailWithRowdata(rowData))}</h4>
      </React.Fragment>
    );
  };

  const phoneBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <h4>{String(findCellphoneWithRowdata(rowData))}</h4>
      </React.Fragment>
    );
  };

  const cadastroDateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <DateText>
          {rowData?.cadastroDate ? parseDate(rowData?.cadastroDate) : "-"}
        </DateText>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Span color={clientStatusColors[rowData?.status]}>
          {rowData?.status ? statusTranslated[rowData?.status] : "-"}
        </Span>
      </React.Fragment>
    );
  };

  const items = [
    {
      label: "Analisar",
      icon: "pi pi-images",
      command: () => {
        dispatch(startAnalize(""));
        dispatch(changeDrawerType("client"));
        dispatch(requestNewAnalaize({ clientId: selectedRow.id }));
      },
    },
    {
      label: "Deletar",
      icon: "pi pi-times",
      command: () => {
        setDeleteConfirmModalOpen(true);
      },
    },
    {
      label: "Mensagens",
      icon: "pi pi-times",
      command: () => {
        history.push(`/client/${selectedRow.id}/messages`);
      },
    },
  ];

  const analistarButtonBodyTemplate = (rowEvent) => {
    return (
      <AnalisarDiv onClick={() => setSelectedRow(rowEvent)}>
        <SplitButton label="Ações" model={items}></SplitButton>
      </AnalisarDiv>
    );
  };

  const IconsBodyTemplate = (rowData) => {
    return (
      <TableIconsBox>
        {rowData?.clientIdent?.justification_reproved ? (
          <Tooltip active={rowData?.clientIdent?.justification_reproved}>
            {<span>{`${rowData?.clientIdent?.justification_reproved}`}</span>}
            <BiMessageRoundedDetail
              color={
                rowData?.clientIdent?.justification_reproved
                  ? "#e7004c"
                  : "#7c878d"
              }
              size={24}
            />
          </Tooltip>
        ) : null}
      </TableIconsBox>
    );
  };

  const onSortChange = (event) => {
    handleOnSortChange(event?.sortField);
  };

  return (
    <Container>
      <DeleteClientDialog
        isOpen={deleteConfirmModalOpen}
        closeModal={() => setDeleteConfirmModalOpen(false)}
        client={selectedRow}
      />
      <div className="card">
        <DataTable
          value={clients}
          loading={loading}
          onSort={(e) => onSortChange(e)}
          emptyMessage="Clientes não encontrados"
          responsiveLayout="scroll"
          breakpoint="1400px"
        >
          <Column field="name" header="Nome" body={nameBodyTemplate} sortable />
          <Column
            field="email"
            header="Email"
            body={emailBodyTemplate}
            sortable
            style={{ width: "25%" }}
          />
          <Column
            field="phone"
            header="Phone"
            body={phoneBodyTemplate}
            sortable
          />
          <Column
            field="created_at"
            header="Data Cadastro"
            body={cadastroDateBodyTemplate}
            sortable
          />
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            sortable
          />
          <Column
            field="Icons"
            header="Justificativa" // Coluna vazia, só aparece se passar o mouse por cima
            body={IconsBodyTemplate}
          />
          <AnalisarColumn
            field=""
            header="" // Coluna vazia, só aparece se passar o mouse por cima
            body={analistarButtonBodyTemplate}
          />
        </DataTable>
      </div>
    </Container>
  );
};

export default Table;
