import produce from "immer";

const INITIAL_STATE = {
  table: {
    data: [],
    filteredData: [],
    options: {
      itemsQnt: 10,
      page: 1,
      step: "",
      status: "",
      email_or_name: "",
      order: "asc",
      sort: "name",
      pageMeta: {
        pagination: {
          count: 1,
        },
      },
    },
    loading: false,
  },
};

export default function causes(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@dashboard/UPDATE_TABLE_REQUEST":
        break;
      case "@dashboard/UPDATE_TABLE_SUCCESS":
        draft.table.data = action.payload.parsedData;
        draft.table.options.page = action.payload.page;
        draft.table.options.itemsQnt = action.payload.itemsQnt;
        draft.table.options.step = action.payload.step;
        draft.table.options.status = action.payload.status;
        draft.table.options.email_or_name = action.payload.email_or_name;
        draft.table.options.order = action.payload.order;
        draft.table.options.sort = action.payload.sort;
        draft.table.options.pageMeta = action.payload.meta.pagination;
        break;
      case "@dashboard/UPDATE_TABLE_FAILURE":
        draft.table.data = [];
        draft.table.options.page = action.payload.page;
        draft.table.options.itemsQnt = action.payload.itemsQnt;
        draft.table.options.step = action.payload.step;
        draft.table.options.status = action.payload.status;
        draft.table.options.email_or_name = action.payload.email_or_name;
        draft.table.options.order = action.payload.order;
        draft.table.options.sort = action.payload.sort;
        break;
      case "@dashboard/TABLE_LOADING_SETTRUE":
        draft.table.loading = true;
        break;
      case "@dashboard/TABLE_LOADING_SETFALSE":
        draft.table.loading = false;
        break;
      case "@auth/SIGN_OUT": {
        draft.table.data = [];
        break;
      }

      default:
    }
  });
}
