import React, { useState } from "react";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import checkIcon from "../../assets/checkfile.svg";
import ReactLoading from "react-loading";
import {
  Container,
  ClientPreview,
  Group,
  ColoredSpan,
  NextClientButton,
  Table,
  Drawer,
  StartAnaliseButton,
  AnaliseDiv,
  DocumentProgress,
  DocumentReview,
  SendDocToMailButton,
} from "./styles";
import ClientDataCard from "./ClientDataCard";
import SendDocumentsModal from "../../components/SendDocumentsToEmailModal";
import history from "../../services/history";
import {
  startAnalize,
  changeVisibleDrawer,
  requestNewAnalaizeSuccess,
  changeDrawerType,
} from "../../store/modules/drawer/actions";
import { useDispatch } from "react-redux";
import { parseISO } from "date-fns";
import {
  statusColors,
  statusTranslated,
  stepColors,
  stepTranslated,
} from "../../utils/formatSpanShadow";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "react-query";
import { CausesService } from "../../services/Cause/CausesService";
import { useRouteParams } from "../../hooks/useRouteParams";

const ClientReview: React.FC = () => {
  const [cardIsOpen, setCardIsOpen] = useState(false);
  const [sendEmailModalIsOpen, setSendEmailModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const params = useRouteParams();
  const causeId = Number(params.get("cause"));
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery(
    ["getCauseToAnalize"],
    () => CausesService.getCause(causeId),
    {
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        history.push("/");
        toast.error("Causa não foi encontrada");
      },
    }
  );

  async function handleNextClient() {
    try {
      const nextCauseId = await CausesService.getNextCause(data?.data?.id!);
      const response = await CausesService.getCause(nextCauseId?.data?.id);
      queryClient.setQueryData("getCauseToAnalize", response);
    } catch (err) {
      toast.error("Não possui mais causas para analise.");
    }
  }

  function parseDate(stringDate: String) {
    if (stringDate && data) {
      const newDate = new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "long",
      }).format(parseISO(data?.data?.informations?.deadline));

      return newDate;
    }
    return null;
  }

  async function handleAnaliseButtonRequest() {
    const { documents } = data?.data!;
    const documentsInAnalize: any = documents?.filter(
      (document: any) => document?.document?.status === "analyze"
    );

    if (documentsInAnalize.length !== 0) {
      dispatch(changeDrawerType("cause"));
      dispatch(
        startAnalize({ documents: documentsInAnalize, type: "AnalyzeButton" })
      );
      dispatch(requestNewAnalaizeSuccess(documentsInAnalize[0]));
      dispatch(changeVisibleDrawer());
    } else {
      toast.error("Não possui documentos para analisar");
    }
  }

  if (isLoading) {
    return (
      <Container>
        <h1>Carregando...</h1>
      </Container>
    );
  }

  return (
    <Container>
      <Drawer />
      <SendDocumentsModal
        isOpen={sendEmailModalIsOpen}
        setIsClosed={setSendEmailModalIsOpen}
        causeId={data?.data?.id!}
      />
      <header>
        <div
          onClick={() => {
            history.push("/");
          }}
        >
          <FiArrowLeft color="#E7004C" size={24} />
          <h1>Voltar</h1>
        </div>
      </header>
      {!isFetching ? (
        <>
          <ClientPreview>
            <h1>{data?.data?.client?.name} </h1>
            <Group>
              <p>
                Data limite: {parseDate(data?.data?.informations?.deadline!)}
              </p>
              <ColoredSpan color={stepColors[data?.data?.step!]}>
                {stepTranslated[data?.data?.step!]}
              </ColoredSpan>
              <ColoredSpan color={statusColors[data?.data?.status!]}>
                {statusTranslated[data?.data?.status!]}
              </ColoredSpan>
              <NextClientButton onClick={handleNextClient}>
                Próximo Cliente
                <FiChevronRight size={24} />
              </NextClientButton>
            </Group>
          </ClientPreview>
          <ClientDataCard
            isOpen={cardIsOpen}
            onClick={setCardIsOpen}
            payment={data?.data?.payment}
            demand={data?.data?.demand}
            contract={data?.data?.contract}
            client={data?.data?.client}
            createdAt={data?.data?.created_at}
          />

          <DocumentReview>
            <Table data={data?.data?.documents} loading={isLoading} />
            <AnaliseDiv>
              <StartAnaliseButton onClick={handleAnaliseButtonRequest}>
                INICIAR ANÁLISE
              </StartAnaliseButton>
              <SendDocToMailButton
                onClick={() => setSendEmailModalIsOpen(!sendEmailModalIsOpen)}
              >
                Enviar para email
              </SendDocToMailButton>
              {data?.data?.informations?.required ? (
                <DocumentProgress
                  title="Obrigatórios"
                  qtd={data?.data?.informations?.required}
                  icon={checkIcon}
                />
              ) : null}
              {data?.data?.informations?.in_analysis ? (
                <DocumentProgress
                  title="Em Analise"
                  qtd={data?.data?.informations?.in_analysis}
                  icon={checkIcon}
                />
              ) : null}
              {data?.data?.informations?.sent ? (
                <DocumentProgress
                  title="Enviados"
                  qtd={data?.data?.informations.sent}
                  icon={checkIcon}
                />
              ) : null}
              {data?.data?.informations?.analyzed ? (
                <DocumentProgress
                  title="Analisados"
                  qtd={data?.data?.informations?.analyzed}
                  icon={checkIcon}
                />
              ) : null}
              {data?.data?.informations?.approved ? (
                <DocumentProgress
                  title="Aprovados"
                  qtd={data?.data?.informations?.approved}
                  icon={checkIcon}
                />
              ) : null}
              {data?.data?.informations?.reproved ? (
                <DocumentProgress
                  title="Reprovados"
                  qtd={data?.data?.informations?.reproved}
                  icon={checkIcon}
                />
              ) : null}
            </AnaliseDiv>
          </DocumentReview>
        </>
      ) : (
        <ReactLoading
          type="bubbles"
          color="var(--primary)"
          height="46px"
          width="46px"
        />
      )}
    </Container>
  );
};

export default ClientReview;
