import { toast } from "react-toastify";
import { Container } from "./styles";
import { documentStatusTranslated } from "../../../utils/formatSpanShadow";
import { useDispatch } from "react-redux";
import api from "../../../services/api";
import { changeVisibleDrawer } from "../../../store/modules/drawer/actions";
import { updateTableRequest } from "../../../store/modules/clients/actions";

const ItemClientIdentityJustification = ({
  status,
  date,
  children,
  clientIdentity,
  tableOptions,
  askForCpf,
  docType
}) => {
  const dispatch = useDispatch();

  async function handleRevert() {
    try {
      await api.put(`/api/v1/clients/${clientIdentity.id}/identity`, {
        status: "confirmed",
        kind_identification: docType,
        ask_for_cpf: askForCpf,
      });
      dispatch(
        updateTableRequest({
          page: tableOptions.page,
          status: tableOptions.status,
          itemsQnt: tableOptions.itemsQnt,
        })
      );
      dispatch(changeVisibleDrawer());
    } catch (err) {
      const { response } = err;
      toast.error(response?.data?.error_parameters[0])
    }
  }

  return (
    <Container >
      <div>
        <label>{documentStatusTranslated[status]}</label>
        <p>{date}</p>
        <span onClick={() => handleRevert()}>
          Reverter {documentStatusTranslated[status]}
        </span>
      </div>
      <p>{children}</p>
    </Container>
  );
};

export default ItemClientIdentityJustification;
