import styled from "styled-components";
import FilterItem from "./FilterItem";
import { Dropdown as PrimeReactDropDown } from "primereact/dropdown";

export const Container = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .p-splitbutton .p-splitbutton-defaultbutton {
    margin-left: 8px;
    background: #ffff;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #374954;
    border: 1px solid #eaedef;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .p-button.p-button-icon-only {
    background: #ffff;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #374954;
    border: 1px solid #eaedef;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const Item = styled(FilterItem)`
  cursor: pointer;
  & + div {
    margin-left: 8px;
  }
`;

interface OutrosProps {
  selected?: boolean;
  onClick?: () => void;
}

export const OutrosFilter = styled.select<OutrosProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: ${({ selected }) => (selected ? "#e7004c" : "#fff ")};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: ${({ selected }) => (selected ? "#fff" : "#374954")};
  border: 1px solid #eaedef;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    color: ${({ selected }) => (selected ? "#fff" : "#e7004c")};
    border: 1px solid #e7004c;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }

  & + span {
    margin-left: 8px;
  }
`;

interface IDropDown {
  selected: string;
}

export const Dropdown = styled(PrimeReactDropDown)<IDropDown>`
  background: ${({ selected }) => (selected ? "#e7004c" : "#fff ")};

  .p-dropdown-label.p-placeholder {
    color: ${({ selected }) => (selected ? "#fff" : "#374954 ")};
  }

  &:hover {
    /* outline: red !important; */
    border: 1px solid red !important;

    .p-dropdown-label.p-placeholder {
      color: ${({ selected }) => (selected ? "#374954" : "#e7004c ")};
    }
  }

  &:focus {
    box-shadow: 0;
    border: 0;
  }
`;

export const NewUserButton = styled(FilterItem)`
  background: var(--primary);
  margin: 0 0.5rem;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;
