import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  > label {
    margin-bottom: 0.4rem;
  }

  .p-dropdown {
    width: 100%;
    border-radius: 12px;
    height: 3rem;
    display: flex;
    align-items: center;
  }
`;

export const SelectFooter = styled.div`
  padding: 0.5rem 1rem;
`;
