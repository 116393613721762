import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "../../../services/api";
import history from "../../../services/history";
import { signInSucess, signFailure } from "./actions";
import { updateTableRequest } from "../causes/actions";

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield api.post("api/v1/auth/sign_in", {
      email,
      password,
    });

    const { access_token, user } = response.data;
    api.defaults.headers.Authorization = access_token;
    localStorage.setItem("@biro:user", JSON.stringify(user));
    yield put(signInSucess(access_token, user));
    yield put(updateTableRequest({ page: 1, itemsQnt: 10 }));
  } catch (err) {
    toast.error("Falha na Autenticação, Verifique seus dados");
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, password } = payload;

    yield call(api.post, "users", { name, email, password, provider: true });

    history.push("/");
  } catch (err) {
    toast.error("Falha no cadastro, verifique seus dados");

    yield put(signFailure());
  }
}

export function* setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    yield (api.defaults.headers.Authorization = token);
  }
}

function signOut() {
  history.push("/");
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_UP_REQUEST", signUp),
  takeLatest("@auth/SIGN_OUT", signOut),
]);
