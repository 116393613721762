import React from "react";
import { Container } from "./styles";

interface ItemProps {
  hasTooltip?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

const FilterItem: React.FC<ItemProps> = ({
  children,
  selected,
  hasTooltip,
  ...rest
}) => {
  return (
    <Container selected={selected} {...rest}>
      {children}
    </Container>
  );
};

export default FilterItem;
