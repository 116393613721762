import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  > svg {
    border-radius: 50%;
    margin: 0 10px;
    transition: background 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      background: #d8d8d8;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    > img {
      display: flex;
      width: 90%;
      height: 100%;
    }
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40vw;
  min-height: 50vh;
`;

export const Content = styled.div`
  display: flex;
  width: 30%;
  flex-direction: row;
  justify-content: space-between;

  svg {
    margin: 5% 0;

    &:hover {
      cursor: pointer;
      filter: brightness(0.5);
    }
  }
`;

export const NoDataBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: row;
  }

  svg {
    border-radius: 50%;
    margin: 0 10px;
    transition: background 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      background: #d8d8d8;
    }
  }
`;
