import { Span } from "./styles";

const ColoredSpan = ({ color, children, ...rest }) => {
  return (
    <Span {...rest} color={color}>
      {children}
    </Span>
  );
};

export default ColoredSpan;
