import React from "react";
import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";
import { Container, Span } from "./styles";
import { parseISO } from "date-fns";
import { useSelector } from "react-redux";
import {
  statusColors,
  stepColors,
  stepTranslated,
  statusTranslated,
} from "../../../utils/formatSpanShadow";
import { Cause, CauseTableRowData } from "../../../types/cause";
import history from "../../../services/history";

interface CauseTranslated extends Cause {
  statusTranslated: string;
  stepTranslated: string;
}

const Table = ({ handleOnSortChange }: any) => {
  const causes = useSelector((state: any) => state.dashboard.table.data);
  const loading = useSelector((state: any) => state.dashboard.table.loading);
  const rows = useSelector(
    (state: any) => state.dashboard.table.options.itemsQnt
  );

  function parseDate(stringDate: string) {
    if (stringDate) {
      const newDate = new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
      }).format(parseISO(stringDate));
      return newDate;
    }
    return null;
  }

  const nameBodyTemplate = (rowData: CauseTableRowData) => {
    return (
      <React.Fragment>
        <h4>{rowData?.name}</h4>
      </React.Fragment>
    );
  };

  const cadastroDateBodyTemplate = (rowData: CauseTableRowData) => {
    return (
      <React.Fragment>
        <span>{parseDate(rowData?.cadastroDate)}</span>
      </React.Fragment>
    );
  };

  const emailTemplate = (rowData: CauseTableRowData) => {
    return (
      <React.Fragment>
        <span>{rowData?.email}</span>
      </React.Fragment>
    );
  };

  const causeCodDateBodyTemplate = (rowData: CauseTableRowData) => {
    return (
      <React.Fragment>
        <span>{rowData?.demand}</span>
      </React.Fragment>
    );
  };

  const inAnalysisBodyTemplate = (rowData: CauseTableRowData) => {
    return (
      <React.Fragment>
        <Span color="#cce8f8">{rowData?.in_analysis}</Span>
      </React.Fragment>
    );
  };

  const stepBodyTemplate = (rowData: CauseTranslated) => {
    return (
      <React.Fragment>
        <Span color={stepColors[rowData?.step]}>{rowData?.stepTranslated}</Span>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData: CauseTranslated) => {
    return (
      <React.Fragment>
        <Span color={statusColors[rowData?.status]}>
          {rowData?.statusTranslated}
        </Span>
      </React.Fragment>
    );
  };

  const analistarButtonBodyTemplate = () => {
    return (
      <React.Fragment>
        <p> Analisar </p>
      </React.Fragment>
    );
  };

  function handleRowClick(rowEvent: any) {
    const { data } = rowEvent;
    history.push(`/causereview?cause=${data?.id}`);
  }

  const translatedCauses = causes.map((cause: Cause) => {
    return {
      ...cause,
      statusTranslated: statusTranslated[cause.status],
      stepTranslated: stepTranslated[cause.step],
    };
  });

  const onSortChange = (event: any) => {
    handleOnSortChange(event?.sortField);
  };

  return (
    <Container>
      <div className="card">
        <DataTable
          value={translatedCauses}
          rows={rows}
          loading={loading}
          resizableColumns
          onRowClick={handleRowClick}
          onSort={(event) => onSortChange(event)}
          emptyMessage="Causas não encontradas"
        >
          <Column
            field="name"
            header="Nome"
            body={nameBodyTemplate}
            sortable
            style={{ width: "23%" }}
          />
          <Column
            field="cadastroDate"
            header="Data Cadastro"
            body={cadastroDateBodyTemplate}
            sortable
            style={{ width: "17%" }}
          />
          <Column
            field="email"
            header="Email"
            body={emailTemplate}
            sortable
            style={{ width: "17%" }}
          />
          <Column
            field="in_analysis"
            header="Doc. Em Análise"
            body={inAnalysisBodyTemplate}
            sortable
            style={{ width: "15%" }}
          />
          <Column
            field="code"
            header="Código"
            body={causeCodDateBodyTemplate}
            sortable
            style={{ width: "15%" }}
          />
          {/* <Column
            field="enviados"
            header="Doc. enviados"
            body={docEnviadosBodyTemplate}
            sortable
            style={{ width: "20%" }}
          />
          <Column
            field="avaliados"
            header="Doc. avaliados"
            body={docAvaliadosBodyTemplate}
            sortable
            style={{ width: "20%" }}
          /> */}
          <Column
            filterField="stepTranslated"
            field="step"
            header="Step"
            body={stepBodyTemplate}
            sortable
            style={{ width: "20%" }}
          />
          <Column
            filterField="statusTranslated"
            field="status"
            header="Status"
            body={statusBodyTemplate}
            sortable
            style={{ width: "20%" }}
          />
          <Column
            field="analisarButton"
            header="" // Coluna vazia, só aparece se passar o mouse por cima
            body={analistarButtonBodyTemplate}
            style={{ width: "100px" }}
          />
        </DataTable>
      </div>
    </Container>
  );
};

export default Table;
