import { useDispatch, useSelector } from "react-redux";
import { updateTableRequest } from "../../../../store/modules/clients/actions";
import { Container } from "./styles";

function PageRows() {
  const dispatch = useDispatch();
  const tableOptions = useSelector((state) => state.clients.table.options);

  return (
    <Container>
      <span>Exibir por página</span>
      <select
        onChange={(event) => dispatch(
          updateTableRequest({
            page: tableOptions.page,
            status: tableOptions.status,
            itemsQnt: event.target.value,
            order: tableOptions.order,
            sort: tableOptions.sort,
            email_or_name: tableOptions.email_or_name,
          })
        )}
      >
        <option>10</option>
        <option>20</option>
        <option>50</option>
      </select>
    </Container>
  );
}

export default PageRows;
