export function updateClientRequest(id) {
  return {
    type: "@clientReview/UPDATE_Client_REQUEST",
    payload: id,
  };
}

export function updateClientSuccess(data) {
  return {
    type: "@clientReview/UPDATE_Client_SUCCESS",
    payload: data,
  };
}
