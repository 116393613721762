import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/es/integration/react";
import GlobalStyle from "./styles/global";
import Routes from "./routes";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import history from "./services/history";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Routes />
            <ToastContainer autoClose={3000} />
            <GlobalStyle />
          </Router>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
