import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.4s ease-in-out;
  height: ${({ isOpen }) => (isOpen ? "auto" : "52px")};
  background: #fff;
`;

export const ClosedCard = styled.div`
  width: 80vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: ${({ isOpen }) => (isOpen ? "#E7004C" : "#7c878d")};
  }
  
  svg {
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: #d8d8d8;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 16px;
`;

export const LeftDetails = styled.div`
  border-right: 1px solid #eaedef;
  height: 100%;
  div {
    margin-top: 15px;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    > h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #7c878d;
    }
    > h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      text-align: right;
      color: #374954;
      margin-right: 30px;
      text-transform: initial;
    }
  }
`;
export const RightDetails = styled.div`
  div {
    margin-top: 15px;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    > h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #7c878d;
      margin-left: 30px;
    }
    > h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      text-align: right;
      color: #374954;
    }
  }
`;
