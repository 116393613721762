import styled from "styled-components";
import AppDrawer from "../../components/Drawer";

export const Container = styled.div`
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > header {
    margin-top: 32px;
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1 {
      font-family: Alegreya;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      display: flex;
      align-items: center;
      color: #7c878d;
    }

    > div {
      background: #fff;
      padding: 0.6rem;
      border: 1px solid #eaedef;
      box-sizing: border-box;
      border-radius: 4px;
      width: 20rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > input {
        background: none;
        border: none;
        &::placeholder {
          color: #7c878d;
        }
      }
      > i {
        color: var(--primary);
      }
    }
  }
`;

export const Main = styled.main`
  margin-top: 32px;
  display: flex;
  width: 80%;
  flex-direction: column;
  height: 60vh;
`;

export const PaginationDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
`;

export const Drawer = styled(AppDrawer)`
  width: 80vw;
  height: 80vh;
  padding: 0;
  margin: 5% 0 0 10%;

  .p-sidebar-icons {
    display: none;
  }
`;
