import { Container, Main, PaginationDiv } from "./styles";
import DataTable from "./DataTable";
import StatusFilters from "../../components/StatusFilters";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTableRequest } from "../../store/modules/causes/actions";
import Paginator from "./Paginator";
import PageRows from "./PageRows";
import { useDebounce } from "../../hooks/useDebounce";

const Dashboard = () => {
  const tableOptions = useSelector((state) => state.dashboard.table.options)
  const [searchInput, setSearchInput] = useState(tableOptions?.email_or_name);
  const searchDebounced = useDebounce(searchInput, 600);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateTableRequest({
        page: 1,
        status: tableOptions.status,
        step: tableOptions.step,
        itemsQnt: tableOptions.itemsQnt,
        order: tableOptions.order,
        sort: tableOptions.sort,
        email_or_name: searchDebounced,
      })
    );
  }, [dispatch, searchDebounced, tableOptions.email_or_name, tableOptions.itemsQnt, tableOptions.order, tableOptions.sort, tableOptions.status, tableOptions.step])

  useEffect(() => {
    dispatch(
      updateTableRequest({
        page: 1,
        status: tableOptions.status,
        step: tableOptions.step,
        itemsQnt: tableOptions.itemsQnt,
        order: tableOptions.order,
        sort: tableOptions.sort,
        email_or_name: tableOptions.email_or_name,
      })
    );
  }, [dispatch,
    tableOptions.email_or_name,
    tableOptions.itemsQnt,
    tableOptions.order,
    tableOptions.sort,
    tableOptions.status,
    tableOptions.step]);

  var filters = [
    { step: "", status: "", label: "Todos" },
    {
      step: "documentation",
      status: "pending",
      label: "Doc Pendente",
    },
    {
      step: "documentation",
      status: "analyze",
      label: "Doc Em Analise",
    },
    {
      step: "documentation",
      status: "concluded",
      label: "Doc Concluido",
    }, /// referentes a documentação
  ];

  function handleFilterOnChange(status, step) {
    dispatch(
      updateTableRequest({
        page: tableOptions.page,
        status: status,
        step: step,
        itemsQnt: tableOptions.itemsQnt,
        order: tableOptions.order,
        sort: tableOptions.sort,
        email_or_name: tableOptions.email_or_name,
      })
    );
  }

  function handleOnSortChange(
    sort
  ) {
    dispatch(
      updateTableRequest({
        page: 1,
        status: tableOptions.status,
        step: tableOptions.step,
        itemsQnt: tableOptions.itemsQnt,
        order:
          sort === tableOptions.sort
            ? tableOptions.order === "asc"
              ? "desc"
              : "asc"
            : "asc",
        sort: sort,
        email_or_name: searchDebounced,
      })
    );
  }


  return (
    <Container>
      <header>
        <h1>Causas</h1>
        <div>
          <input
            placeholder="Buscar"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <i className="pi pi-search p-mr-2" />
        </div>
      </header>
      <StatusFilters
        filters={filters}
        onChange={handleFilterOnChange}
        hasOthers
      />
      <Main>
        <DataTable handleOnSortChange={handleOnSortChange} />
        <PaginationDiv>
          <PageRows />
          <Paginator />
        </PaginationDiv>
      </Main>
    </Container>
  );
};

export default Dashboard;
