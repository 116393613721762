type updateTableProps = {
  page?: number;
  rows?: number;
  itemsQnt?: string;
  status?: string;
  email_or_name?: string;
  order?: "asc" | "desc" | "";
  sort?: "name" | "email" | "create_at" | "status" | "step" | "";
};

export function updateTableRequest(payload: updateTableProps) {
  return {
    type: "@clients/UPDATE_TABLE_REQUEST",
    payload,
  };
}

export function filterTable(payload: string) {
  return {
    type: "@clients/FILTER_TABLE",
    payload,
  };
}

export function tableLoadingTrue() {
  return {
    type: "@clients/TABLE_LOADING_SETTRUE",
  };
}

export function tableLoadingFalse() {
  return {
    type: "@clients/TABLE_LOADING_SETFALSE",
  };
}

export function updateTableSuccess(payload: any) {
  return {
    type: "@clients/UPDATE_TABLE_SUCCESS",
    payload,
  };
}

export function updateTableFailure() {
  return {
    type: "@clients/UPDATE_TABLE_FAILURE",
  };
}

export function createNewClientRequest(payload: any) {
  return {
    type: "@clients/CREATE_NEW_CLIENT_REQUEST",
    payload,
  };
}

export function createNewClientSuccess(payload: any) {
  return {
    type: "@clients/CREATE_NEW_CLIENT_SUCCESS",
    payload,
  };
}

export function createNewClientFailure(payload: any) {
  return {
    type: "@clients/CREATE_NEW_CLIENT_FAILURE",
    payload,
  };
}

export function getAlldemandsRequest() {
  return {
    type: "@clients/GET_DEMANDS_REQUEST",
  };
}

export function getAlldemandsSuccess(payload: any) {
  return {
    type: "@clients/GET_DEMANDS_SUCCESS",
    payload,
  };
}

export function getAlldemandsFailure() {
  return {
    type: "@clients/GET_DEMANDS_FAILURE",
  };
}

export function clientsLoadingTrue() {
  return {
    type: "@clients/Clients_LOADING_SETTRUE",
  };
}

export function clientsLoadingFalse() {
  return {
    type: "@clients/Clients_LOADING_SETFALSE",
  };
}

export function deleteClientRequest(payload: any) {
  return {
    type: "@clients/DELETE_CLIENT_REQUEST",
    payload,
  };
}

export function deleteClientSuccess(payload: any) {
  return {
    type: "@clients/DELETE_CLIENT_SUCCESS",
    payload,
  };
}

export function deleteClientFailure(payload: any) {
  return {
    type: "@clients/DELETE_CLIENT_FAILURE",
    payload,
  };
}
