import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #374954;
  }

  > select {
    margin-left: 16px;
    padding: 8px 16px;
    background: #ffffff;
    border: 1px solid #eaedef;
    box-sizing: border-box;
    border-radius: 4px;

    svg {
      margin-right: 40px;
    }
  }
`;
