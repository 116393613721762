/* eslint-disable import/no-anonymous-default-export */
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: "biro",
      storage,
      whitelist: ["auth", "user", "dashboard", "clientReview"],
    },
    reducers
  );

  return persistedReducer;
};
