import {
  Container,
  ImageAnalise,
  Button,
  Group,
  DeclinedContent,
  DeclinedButtonDiv,
  ContentTitle,
  ClientFooter,
  ImageBox,
  HistoryContent,
  DeclineButton,
  Input,
  FooterSelectBox,
} from "./styles";
import { Sidebar } from "primereact/sidebar";
import { SelectButton } from "primereact/selectbutton";
import { useDispatch, useSelector } from "react-redux";
import {
  changeVisibleDrawer,
  callNextDocumentToAnalyze,
  drawerChangeDocType,
  drawerChangeAskForCpf,
} from "../../store/modules/drawer/actions";
import { FiAlertCircle, FiX, FiCheck } from "react-icons/fi";
import ReactLoading from "react-loading";
import ItemDocHistory from "./ItemDocHistory";
import { useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { updateClientRequest } from "../../store/modules/clientReview/actions";
import Carousel from "../Carrousel";
import { updateTableRequest } from "../../store/modules/clients/actions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { cpfMask } from "../../utils/cpfMask";
import { parseISO } from "date-fns";
import ItemClientIdentityJustification from "./ItemClientIdentityJustification";
import { BiDownload } from "react-icons/bi";
import { saveAs } from 'file-saver'
import { useQueryClient } from "react-query";

const Drawer = ({ ...rest }) => {
  const [textAreaValue, setTextAreaValue] = useState("");
  const queryClient = useQueryClient();

  const CauseData = queryClient.getQueryData('getCauseToAnalize');

  const userInfo = CauseData?.data;

  const clientInfo = useSelector((state) => state.drawer);
  const [tableOptions] = useState(
    useSelector((state) => state.clients.table.options)
  );


  const [monthInputValue, setMonthInputValue] = useState("");
  const [yearInputValue, setYearInputValue] = useState("");

  const {
    clientIdentity,
    loading,
    type,
    document,
    documentsInAnalyze,
    openedWith,
    visible: isOpen,
  } = clientInfo;


  const { askForCpf, docType } = clientIdentity;

  const dispatch = useDispatch();

  const options = ["rg", "cnh"];
  const askCpfInDocOptions = ["Sim", "Não"];

  async function handleApproved() {
    if (openedWith === "AnalyzeButton") {
      try {
        await api.put(`/api/v1/documents/${document.id}`, {
          status: "aproved",
          month: monthInputValue,
          year: yearInputValue,
        });
        dispatch(updateClientRequest(userInfo?.id));
        setMonthInputValue("");
        setYearInputValue("");
        setTextAreaValue("");
      } catch (error) {
        return toast.error(error?.response?.data?.error_parameters[0]);
      }

      if (documentsInAnalyze.length !== 0) {
        dispatch(callNextDocumentToAnalyze());
      } else {
        dispatch(changeVisibleDrawer());
        queryClient.invalidateQueries('getCauseToAnalize');
        return toast.error("Não possui mais documentos para analisar");
      }
      return;
    }

    if (type === "client") {
      if (docType === "" || askForCpf === "") {
        return toast.error("Insira todos os campos necessários para Aprovar");
      }
      try {
        await api.put(`/api/v1/clients/${clientIdentity.id}/identity`, {
          status: "confirmed",
          kind_identification: docType,
          ask_for_cpf: askForCpf,
        });
        dispatch(
          updateTableRequest({
            page: tableOptions.page,
            status: tableOptions.status,
            itemsQnt: tableOptions.itemsQnt,
          })
        );
        setTextAreaValue("");
        dispatch(changeVisibleDrawer());
      } catch (error) {
        toast.error(error?.response?.data?.error_parameters[0]);
      }
    } else {
      try {
        await api.put(`/api/v1/documents/${document.id}`, {
          status: "aproved",
          month: monthInputValue,
          year: yearInputValue,
        });
        setTextAreaValue("");
        dispatch(updateClientRequest(userInfo?.id));
        dispatch(changeVisibleDrawer());
        setMonthInputValue("");
        setYearInputValue("");
        queryClient.invalidateQueries('getCauseToAnalize');
      } catch (error) {
        toast.error(error?.response?.data?.error_parameters[0]);
      }
    }
  }

  async function handleReproved() {
    if (openedWith === "AnalyzeButton") {
      try {
        await api.put(`/api/v1/documents/${document.id}`, {
          status: "reproved",
          justification: textAreaValue,
        });
        setTextAreaValue("");
        dispatch(updateClientRequest(userInfo?.id));
      } catch (error) {
        toast.error(error?.response?.data?.error_justification_blank[0]);
      }

      if (documentsInAnalyze.length !== 0) {
        dispatch(callNextDocumentToAnalyze());
      } else {
        dispatch(changeVisibleDrawer());
        queryClient.invalidateQueries('getCauseToAnalize');
        return toast.error("Não possui mais documentos para analisar");
      }
      return;
    }

    if (type === "client") {
      if (docType === "" || askForCpf === "") {
        return toast.error("Insira todos os campos necessários para reprovar");
      }
      try {
        await api.put(`/api/v1/clients/${clientIdentity.id}/identity`, {
          status: "reproved",
          justification: textAreaValue,
        });
        dispatch(
          updateTableRequest({
            page: tableOptions.page,
            status: tableOptions.status,
            itemsQnt: tableOptions.itemsQnt,
          })
        );
        dispatch(changeVisibleDrawer());
        setMonthInputValue("");
        setTextAreaValue("");
        setYearInputValue("");
      } catch (error) {
        toast.error(error?.response?.data?.error_justification_blank[0]);
      }
    } else {
      try {
        await api.put(`/api/v1/documents/${document.id}`, {
          status: "reproved",
          justification: textAreaValue,
        });
        setTextAreaValue("");
        dispatch(updateClientRequest(userInfo?.id));
        dispatch(changeVisibleDrawer());
        queryClient.invalidateQueries('getCauseToAnalize');
      } catch (error) {
        toast.error(error?.response?.data?.error_justification_blank[0]);
      }
    }
  }

  var dateOptions = { day: 'numeric', year: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' };

  function parseDate(stringDate) {
    if (stringDate) {
      const newDate = new Intl.DateTimeFormat("pt-BR", dateOptions).format(parseISO(stringDate));
      return newDate;
    }
    return null;
  }

  const downloadImage = () => {
    saveAs(document?.url, 'document.jpg') // Put your image url here.
  }

  if (type === "client") {
    return (
      <Sidebar
        showCloseIcon={false}
        visible={isOpen}
        position="top"
        onHide={() => {
          dispatch(changeVisibleDrawer());
        }}
        blockScroll
        {...rest}
      >
        <Container>
          <header>
            <div>
              <span>Frente/Verso</span>
            </div>
            <FiX
              color="#E7004C"
              size={28}
              onClick={() => {
                dispatch(changeVisibleDrawer());
              }}
            />
          </header>
          <ImageAnalise>
            {document.observation ? (
              <span>{document.observation}</span>
            ) : (
              <ImageBox>
                <Carousel items={clientIdentity?.images} />
              </ImageBox>
            )}
            {clientIdentity?.data?.status === 'reproved' ? <HistoryContent>
              <ContentTitle>Justificativa</ContentTitle>
              <div>
                <ItemClientIdentityJustification
                  status={clientIdentity?.data?.status}
                  date={parseDate(clientIdentity?.data?.updated_at)}
                  clientInfo={userInfo}
                  clientIdentity={clientIdentity}
                  tableOptions={tableOptions}
                  document={document}
                  docType={docType}
                  askForCpf={askForCpf}
                >
                  {clientIdentity?.data?.justification_reproved}
                </ItemClientIdentityJustification>
              </div>
            </HistoryContent>
              :
              <DeclinedContent>
                <ContentTitle>Reprovar</ContentTitle>
                <p>Escreva sua justificativa</p>
                <textarea
                  placeholder="Ex: Foto escura, por favor tente novamente."
                  value={textAreaValue}
                  onChange={(event) => setTextAreaValue(event.target.value)}
                />
                <span>Máximo de 140 caracteres</span>
                <DeclinedButtonDiv>
                  <div />
                  <div>
                    <span onClick={() => setTextAreaValue("")}>
                      Limpar Justificativa
                    </span>
                  </div>
                  <DeclineButton onClick={handleReproved}>
                    <FiX color="#fff" size={30} />
                    Reprovar Documento
                  </DeclineButton>
                </DeclinedButtonDiv>
              </DeclinedContent>
            }

          </ImageAnalise>
          <footer>
            <Group>
              <h6>Conferência neste documento</h6>
              <ClientFooter>
                <div>
                  {clientIdentity?.data?.name ? (
                    <p>
                      Nome:
                      <span> {clientIdentity?.data?.name}</span>
                    </p>
                  ) : null}
                  {clientIdentity?.data?.birth_date ? (
                    <p>
                      Nascimento:
                      <span>{parseDate(clientIdentity?.data?.birth_date)}</span>
                    </p>
                  ) : null}
                </div>
                <div>
                  {clientIdentity?.data?.email ? (
                    <p>
                      Email:
                      <span> {clientIdentity?.data?.email}</span>
                    </p>
                  ) : null}
                  {clientIdentity?.data?.phone ? (
                    <p>
                      Phone:
                      <span>{clientIdentity?.data?.phone}</span>
                    </p>
                  ) : null}
                </div>
                <div>
                  {clientIdentity?.data?.cpf ? (
                    <p>
                      CPF:
                      <span> {cpfMask(clientIdentity?.data?.cpf)}</span>
                    </p>
                  ) : null}

                  {clientIdentity?.data?.rg ? (
                    <p>
                      RG:
                      <span> {clientIdentity?.data?.rg}</span>
                    </p>
                  ) : null}
                </div>
                <div>
                  <FooterSelectBox>
                    <p>Tipo do documento:</p>
                    <SelectButton
                      className="primeSelect"
                      value={docType}
                      options={options}
                      onChange={(event) =>
                        dispatch(drawerChangeDocType(event.target.value))
                      }
                    />
                  </FooterSelectBox>

                  <FooterSelectBox>
                    <p>Pedir Cpf na documentação:</p>
                    <SelectButton
                      className="primeSelect"
                      value={
                        askForCpf ? "Sim" : askForCpf === false ? "Não" : ""
                      }
                      options={askCpfInDocOptions}
                      onChange={(event) =>
                        dispatch(drawerChangeAskForCpf(event.target.value === 'Sim' ? true : event.target.value === 'Não' ? false : null))
                      }
                    />
                  </FooterSelectBox>
                </div>
              </ClientFooter>
            </Group>
            <Button
              // active={document?.history[0]?.history ? true : false}
              onClick={handleApproved}
            >
              <FiCheck color="#fff" size={30} />
              Aprovar e continuar
            </Button>
          </footer>
        </Container>
      </Sidebar>
    );
  }

  return (
    <Sidebar
      showCloseIcon={false}
      visible={isOpen}
      position="top"
      onHide={() =>
        dispatch(changeVisibleDrawer())
      }
      blockScroll
      {...rest}
    >
      <Container>
        <header>
          <div>
            <span>{document.name}</span>
            <FiAlertCircle color="#7C878D" size={24} />
            {document?.type?.search("pdf") < 0 ?
              <BiDownload color="#7C878D" size={24} onClick={downloadImage} />
              : null}
          </div>

          <FiX
            color="#E7004C"
            size={28}
            onClick={() =>
              dispatch(changeVisibleDrawer())
            }
          />
        </header>

        <ImageAnalise>
          {document.observation ? (
            <span>{document.observation}</span>
          ) : (
            <ImageBox>
              {loading ? (
                <ReactLoading
                  type="bubbles"
                  color="#E7004C"
                  height="96px"
                  width="96px"
                />
              ) : document?.type?.search("pdf") > 0 ? (
                <embed
                  src={document.url}
                  type="application/pdf"
                  title="Documento"
                />
              ) : (
                <TransformWrapper>
                  <TransformComponent contentStyle={{ width: "100%" }}>
                    <img src={document.url} alt="Documento" />
                  </TransformComponent>
                </TransformWrapper>
              )}
            </ImageBox>
          )}

          <DeclinedContent>
            <ContentTitle>Reprovar</ContentTitle>
            <p>Escreva sua justificativa</p>
            <textarea
              placeholder="Ex: Foto escura, por favor tente novamente."
              value={textAreaValue}
              onChange={(event) => setTextAreaValue(event.target.value)}
            />
            <span>Máximo de 1000 caracteres</span>

            <DeclinedButtonDiv>
              <div />
              <div>
                <span onClick={() => setTextAreaValue("")}>
                  Limpar Justificativa
                </span>
              </div>
              <DeclineButton
                disabled={
                  document?.status === "analyze" && textAreaValue !== ""
                    ? false
                    : true
                }
                onClick={
                  document?.status === "analyze" && textAreaValue !== ""
                    ? handleReproved
                    : null
                }
              >
                <FiX color="#fff" size={30} />
                Reprovar Documento
              </DeclineButton>
            </DeclinedButtonDiv>
          </DeclinedContent>
          <HistoryContent>
            <ContentTitle>Histórico</ContentTitle>
            <div>
              {document.history
                ? document.history.map((item, index) => (
                  <ItemDocHistory
                    key={item.history.id}
                    status={item.history.status}
                    date={parseDate(item.history.updated_at)}
                    revert={index === 0}
                    userInfo={userInfo}
                    options={{
                      type: document.option,
                      month: monthInputValue,
                      year: yearInputValue,
                      status: document.status,
                    }}
                  >
                    {item.history.justification}
                  </ItemDocHistory>
                ))
                : null}
            </div>
          </HistoryContent>
        </ImageAnalise>
        <footer>
          <Group>
            <h6>Conferência neste documento</h6>
            <span>{userInfo?.client?.name}</span>
            <span>{userInfo?.contract?.cpf}</span>
          </Group>

          {document?.option === "paycheck" ? (
            <Group>
              <div style={{ marginLeft: "25vw" }}>
                <h6>Mês:</h6>
                <Input
                  name="montly"
                  style={{ width: 30 }}
                  value={monthInputValue}
                  onChange={(event) => setMonthInputValue(event.target.value)}
                />
              </div>
              <div style={{ marginLeft: "25vw" }}>
                <h6>Ano:</h6>
                <Input
                  name="year"
                  style={{ width: 40 }}
                  value={yearInputValue}
                  onChange={(event) => setYearInputValue(event.target.value)}
                />
              </div>
            </Group>
          ) : null}

          {document?.option === "paycheck" ? (
            <Button
              disabled={
                document?.status === "analyze" &&
                  monthInputValue &&
                  yearInputValue
                  ? false
                  : true
              }
              onClick={
                document?.status === "analyze" &&
                  monthInputValue &&
                  yearInputValue
                  ? handleApproved
                  : null
              }
            >
              <FiCheck color="#fff" size={30} />
              Aprovar e continuar
            </Button>
          ) : (
            <Button
              disabled={document?.status === "analyze" ? false : true}
              onClick={document?.status === "analyze" ? handleApproved : null}
            >
              <FiCheck color="#fff" size={30} />
              Aprovar e continuar
            </Button>
          )}
        </footer>
      </Container>
    </Sidebar>
  );
};

export default Drawer;
