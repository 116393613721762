export type Client = {
  id: Number;
  name: String;
  created_at: String;
  status: ClientStatus;
  email: string;
  phone: string;
  client_identity: object;
};

export enum ClientStatus {
  pending = "pending",
  pending_generation = "pending_generation",
  pending_subscription = "pending_subscription",
  analyze = "analyze",
  confirmed = "confirmed",
  reproved = "reproved",
}
