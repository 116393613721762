import { useLocation } from "react-router-dom";
import history from "../../services/history";
import IssuesList from "./components/IssuesList";
import IssueInfo from "./components/IssueInfo";

import { Container, Content } from "./styles";

const Chat = () => {
  const { pathname } = useLocation();
  const url = pathname.split("/", 3);
  const id = url[2];

  if (!id || id === "" || Number(id) < 1) {
    history.goBack();
  }

  return (
    <Container>
      <Content>
        <IssuesList clientId={id} />
        <IssueInfo />
      </Content>
    </Container>
  );
};

export default Chat;
