import { useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Container } from "./styles";
import { getAlldemandsRequest } from "../../store/modules/clients/actions";
import { useDispatch, useSelector } from "react-redux";

interface CausesSelectProps {
  selectedCauses: any;
  setSelectedCauses: any;
}

const CausesSelect = ({
  selectedCauses,
  setSelectedCauses,
}: CausesSelectProps) => {
  const dispatch = useDispatch();
  const demands = useSelector((state: any) => state.clients.demands);

  useEffect(() => {
    function getAllDemands() {
      dispatch(getAlldemandsRequest());
    }
    getAllDemands();
  }, [dispatch]);

  const countryTemplate = (option: any) => {
    return (
      <div className="country-item">
        <div>{option.code}</div>
      </div>
    );
  };

  return (
    <Container>
      <label>Insira as demandas</label>
      <Dropdown
        value={selectedCauses}
        options={demands?.data?.length > 0 ? demands?.data : []}
        onChange={(e) => setSelectedCauses(e.value)}
        optionLabel="code"
        placeholder="Select as demandas"
        filter
        itemTemplate={countryTemplate}
      />
    </Container>
  );
};

export default CausesSelect;
