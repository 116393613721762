import { takeLatest, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { updateClientSuccess } from "./actions";
import { signOut } from "../auth/actions";

export function* updateClient({ payload: id }) {
  try {
    const { data } = yield api.get(`/api/v1/causes/${id}`);
    yield put(updateClientSuccess(data));
  } catch (error) {
    if (error.response.status === 401) yield put(signOut());
    toast.error("Causa não encontrada");
  }
}

export default all([
  takeLatest("@clientReview/UPDATE_Client_REQUEST", updateClient),
]);
