import { CausesApi } from "./CausesApi";
import { CauseInterface, GetNextCauseId } from "./types";

const getCauses = async (): Promise<any> => {
  const causes = await CausesApi.getCauses();
  return causes;
};

const getDocumentsOfCause = async (id: number): Promise<any> => {
  const { data } = await CausesApi.getDocumentsOfCause(id);
  return data;
};

const getCause = async (id: number): Promise<CauseInterface> => {
  const cause = await CausesApi.getCause(id);
  return cause;
};

const getNextCause = async (id: number): Promise<GetNextCauseId> => {
  const cause = await CausesApi.getNextCause(id);
  return cause;
};

export const CausesService = {
  getCauses,
  getCause,
  getNextCause,
  getDocumentsOfCause,
};
