import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 4.35rem;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1rem;

  > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

    label {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 140%;
      color: #a6042b;
    }

    p {
      margin-left: 0.5rem;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 140%;
      color: #7c878d;
    }

    > span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: ${({ active }) => active ? '#e7004c' : '#7c878d'};
      text-decoration-line: underline;
      margin-left: 2rem;
      cursor: ${({ active }) => active ? 'pointer' : 'not-allowed'};;
    }

    svg {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  > p {
    margin-top: 0.5rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #374954;
    word-break: break-all;
  white-space: normal;
  }

  border-bottom: 1px solid #eaedef;
`;

export const Input = styled.input`
  margin-left: 1rem;
  width: 50%;
  padding: 0px;
  height: 3rem;
  background: #f4f5f5;
  border: 1px solid #aab6bc;
  box-sizing: border-box;
  border-radius: 4.8px;
  padding: 0.4rem 0.4rem;
`;
