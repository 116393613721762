import { createGlobalStyle } from "styled-components";

import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`
 * {
   margin: 0;
   padding: 0;
   box-sizing: 0;
   outline: 0;
  }

  :root {
  --white: #ffffff;
  --gray-100: #f6f6f6;
  --gray-200: #E5E5E5;
  --gray-300: #a8a8b3;
  --gray-700: #323238;
  --gray-800: #29292e;
  --gray-850: #1f2729;
  --gray-900: #121214;

  --cyan-500: #61dafb;
  --yellow-500: #eba417;
  --primary: #e7004c;
  --title: #7C878D;
  --black: black;

}

.Toastify__toast-container {
  word-break: break-all;
  white-space: normal;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}


 body {
  background: var(--gray-100);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
 }

 body, input, button, p, span, label {
   font-family: 'Roboto', serif;
   font-size: 16px;
 }

 h1, h2, h3, h4, h5, h6, strong {
   font-weight: 500;
 }

 button {
   cursor: pointer;
 }

 .Modal {
    position: absolute;
    top: 30%;
    left: 25%;
    right: 25%;
    bottom: 30%;
    overflow: auto;
    transition: all 0.8s ease-in-out;
    
    
    header {
      padding: 1rem;
      display: flex;
      height: 30px;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #374954;
      }

      svg {
        color: #374954;
        cursor: pointer;
        padding: 4px;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
          border-radius: 50%;
        }
      }
    }

    footer {
      display: flex;
      height: auto;
      background: #fff;
      align-items: center;
      justify-content: space-between;
    }
  }

  .FlexModal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .Overlay {
    transition: all 0.8s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }


`;
