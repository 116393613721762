import styled, { css } from "styled-components";

import Tooltip from "../Tooltip";

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  background: string;
  color: string;
  border: string;
  width: string | number;
  height: string | number;
  placeholderColor: string | number;
}

export const Container = styled.div<ContainerProps>`
  background: ${({ background }) => (background ? background : "#232129")};
  border-radius: 10px;
  padding: 16px;
  width: ${({ width }) => (width ? `${width}rem` : "100%")};
  height: ${({ height }) => (height ? `${height}rem` : "100%")};
  border: ${({ border }) => (border ? border : "#2px solid #232129")};

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: var(--primary);
      border-color: var(--primary);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: var(--primary);
    `}


  input {
    background: transparent;
    flex: 1;
    color: ${({ color }) => (color ? color : "#f4ede8")};
    border: 0;

    &::placeholder {
      color: ${({ placeholderColor }) =>
        placeholderColor ? placeholderColor : "#666360"};
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
