import { Container, Page } from "./styles";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { updateTableRequest } from "../../../store/modules/causes/actions";

function Paginator() {
  const dispatch = useDispatch();
  const tableOptions = useSelector((state) => state.dashboard.table.options);
  const { page: currentPage, pageMeta: meta } = tableOptions;
  const { count } = meta;
  const { itemsQnt } = tableOptions;
  const lastPage = Math.ceil(count / itemsQnt);

  const paginationAuxArray = [
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2,
  ];

  function generatePagination() {
    const pages = paginationAuxArray.map((page) => {
      if (page > 0 && page <= lastPage) {
        return (
          <Page
            key={page}
            HighlightThisPage={page === currentPage}
            onClick={() => {
              dispatch(
                updateTableRequest({
                  page: page,
                  itemsQnt: tableOptions.itemsQnt,
                  step: tableOptions.step,
                  status: tableOptions.status,
                  order: tableOptions.order,
                  sort: tableOptions.sort,
                  email_or_name: tableOptions.email_or_name,
                })
              );
            }}
          >
            {page}
          </Page>
        );
      }

      return null;
    });

    return pages;
  }

  return (
    <Container>
      {itemsQnt !== 0 ? (
        <Page
          disabled={currentPage === 1}
          style={{ width: "56px" }}
          onClick={() => {
            currentPage !== 1
              ? dispatch(
                updateTableRequest({
                  page: tableOptions.page + -1,
                  itemsQnt: tableOptions.itemsQnt,
                  order: tableOptions.order,
                  sort: tableOptions.sort,
                  email_or_name: tableOptions.email_or_name,
                })
              )
              : console.log();
          }}
        >
          <FiChevronLeft size={20} />
        </Page>
      ) : null}

      {generatePagination()}

      {itemsQnt !== 0 ? (
        <Page
          disabled={currentPage === lastPage}
          style={{ width: "56px" }}
          onClick={() => {
            currentPage !== lastPage
              ? dispatch(
                updateTableRequest({
                  page: tableOptions.page + 1,
                  itemsQnt: tableOptions.itemsQnt,
                  order: tableOptions.order,
                  sort: tableOptions.sort,
                  email_or_name: tableOptions.email_or_name,
                })
              )
              : console.log();
          }}
        >
          <FiChevronRight size={20} />
        </Page>
      ) : null}
    </Container>
  );
}

export default Paginator;
