import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.button`
  background: ${({ disabled }) => (disabled ? "#d8d8d8" : `var(--primary)`)};
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: #312e38;
  width: 340px;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;
  cursor: ${({ disabled }) => (disabled ? "no-drop" : `pointer`)};

  &:hover {
    background: ${({ disabled }) =>
      disabled ? "#d8d8d8" : `${shade(0.2, "#e7004c")}`};
  }
`;
