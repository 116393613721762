import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useCallback, useRef, useState } from "react";
import { FiArrowLeft, FiMail } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

import history from "../../../services/history";
import {
  createNewClientRequest,
  updateTableRequest,
} from "../../../store/modules/clients/actions";
import getValidationErrors from "../../../utils/getValidationErrors";
import * as Yup from "yup";
import {
  Container,
  Content,
  FormContainer,
  NewClientButton,
  NewClientInput,
} from "./styles";
import CausesSelect from "../../../components/CausesSelect";
import { FaUser } from "react-icons/fa";
import { Cause } from "../../../types/cause";

const NewClient: React.FC = () => {
  const dispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const [causesSelected, setCausesSelected] = useState<Cause[]>([]);
  const loading = useSelector((state: any) => state.clients.loading);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email("Digite um e-mail válido")
            .required("E-mail obrigatório"),
          name: Yup.string().required("Nome obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(createNewClientRequest({ ...data, causesSelected }));
        formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [causesSelected, dispatch]
  );

  return (
    <Container>
      <Content>
        <header>
          <div
            onClick={() => {
              history.goBack();
              dispatch(updateTableRequest({ page: 1, itemsQnt: "10" }));
            }}
          >
            <FiArrowLeft color="#E7004C" size={24} />
            <h1>Voltar</h1>
          </div>
        </header>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <FormContainer>
            <h1>Insira os dados do cliente</h1>
            <NewClientInput
              name="name"
              placeholder="Nome"
              icon={FaUser}
              background="var(--gray-200)"
              height="3"
              color="var(--black)"
            />
            <NewClientInput
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              background="var(--gray-200)"
              height="3"
              color="var(--black)"
            />
            <CausesSelect
              selectedCauses={causesSelected}
              setSelectedCauses={setCausesSelected}
            />
            <NewClientButton type="submit" loading={loading}>
              Cadastrar
            </NewClientButton>
          </FormContainer>
        </Form>
      </Content>
    </Container>
  );
};

export default NewClient;
