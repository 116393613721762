import { takeLatest, put, all, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "../../../services/api";
import {
  updateTableFailure,
  updateTableSuccess,
  tableLoadingTrue,
  tableLoadingFalse,
  getAlldemandsFailure,
  getAlldemandsSuccess,
  clientsLoadingTrue,
  clientsLoadingFalse,
  updateTableRequest,
} from "./actions";
import history from "../../../services/history";
import { getClientTableoptions } from "./selector";
import { signOut } from "../auth/actions";

export function* updateTable({ payload }) {
  let { page, itemsQnt, status, sort, order, email_or_name } = payload;

  try {
    yield put(tableLoadingTrue());

    const { data } = yield api.get(
      `/api/v1/clients?items=${itemsQnt}&page=${page}${
        status ? `&status=${status}` : ""
      }${sort ? `&sort=${sort}` : ""}${order ? `&order=${order}` : ""}${
        email_or_name ? `&email_or_name=${email_or_name}` : ""
      }`
    );

    let { meta } = data;
    if (meta === undefined) {
      itemsQnt = 0;
      meta = {
        pagination: {
          page: 1,
          pages: 1,
          last: 1,
          from: 1,
          items: 0,
          to: 0,
        },
      };
    }

    console.log({ data });

    const parsedData = data?.data?.map((item) => {
      return {
        id: item.id,
        name: item.name,
        email: item.email,
        cadastroDate: item.created_at,
        status: item.status,
        phone: item.phone,
        clientIdent: item.client_identity,
      };
    });

    yield put(tableLoadingFalse());
    yield put(
      updateTableSuccess({
        parsedData,
        page,
        itemsQnt,
        status,
        meta,
        sort,
        order,
        email_or_name,
      })
    );
    history.push("/clients");
  } catch (error) {
    if (error.response.status === 401) yield put(signOut());
    toast.error("Cliente com esse status não encontrado");
    yield put(tableLoadingFalse());
    yield put(updateTableFailure());
  }
}

export function* createNewClient({ payload }) {
  const { email, name, causesSelected } = payload;
  yield put(clientsLoadingTrue());
  console.log({ causesSelected });

  const { data } = yield api.post(`/api/v1/clients`, {
    demand_id: causesSelected.id,
    name,
    email,
  });

  if (data?.message?.exist_client_message)
    toast.error(data?.message?.exist_client_message);
  else {
    toast.success(data?.message?.send_email_success[0]);
  }
  yield put(clientsLoadingFalse());
}

export function* deleteClient({ payload }) {
  const { id } = payload;
  yield put(clientsLoadingTrue());
  let tableOptions = yield select(getClientTableoptions);

  yield api.delete(`/api/v1/clients/${id}`);

  toast.success("Cliente deletado com sucesso");

  yield put(clientsLoadingFalse());

  yield put(
    updateTableRequest({
      page: tableOptions.page,
      itemsQnt: tableOptions.itemsQnt,
      status: tableOptions.status,
    })
  );
}

export function* getAllDemands() {
  try {
    const { data } = yield api.get(`/api/v1/demands`);

    yield put(getAlldemandsSuccess(data));
    yield put(clientsLoadingFalse());
  } catch {
    yield put(clientsLoadingFalse());
    yield put(getAlldemandsFailure());
  }
}

export default all([
  takeLatest("@clients/UPDATE_TABLE_REQUEST", updateTable),
  takeLatest("@clients/CREATE_NEW_CLIENT_REQUEST", createNewClient),
  takeLatest("@clients/DELETE_CLIENT_REQUEST", deleteClient),
  takeLatest("@clients/GET_DEMANDS_REQUEST", getAllDemands),
]);
