import { Container, Content } from "./styles";

interface IDocumentProgress {
  title: string;
  icon: string;
  qtd: number;
}

function DocumentProgress({ title, icon, qtd, ...rest }: IDocumentProgress) {
  return (
    <Container {...rest}>
      <Content>
        <p>{title}</p>
        <img src={icon} alt="icone" />
        <h1>{qtd}</h1>
      </Content>
    </Container>
  );
}

export default DocumentProgress;
