export function changeVisibleDrawer() {
  return {
    type: "@drawer/CHANGE_IS_VISIBLE",
  };
}

export function changeDrawerType(payload) {
  return {
    type: "@drawer/CHANGE_TYPE",
    payload,
  };
}

export function startAnalize(payload) {
  return {
    type: "@drawer/START_ANALYZE",
    payload
  };
}

export function changeAnalyzeType(payload) {
  return {
    type: "CHANGE_ANALYZE_TYPE",
    payload
  };
}

export function callNextDocumentToAnalyze(payload) {
  return {
    type: "@drawer/ANALYZE_NEXT",
    payload
  };
}

export function requestNewAnalaize(payload) {
  return {
    type: "@drawer/REQUEST_NEW_ANALIZE",
    payload,
  };
}

export function requestNewAnalaizeSuccess(payload) {
  return {
    type: "@drawer/REQUEST_NEW_ANALIZE_SUCCESS",
    payload,
  };
}

export function drawerLoadingChangeState() {
  return {
    type: "@drawer/CHANGE_LOADING_STATE",
  };
}

export function refreshAnalyzeList(payload) {
  return {
    type: "@drawer/REFRESH_DOCUMENTSTOANALYZE_LIST",
    payload
  };
}

export function drawerChangeAskForCpf(payload) {
  return {
    type: "@drawer/CHANGE_ASK_FOR_CPF",
    payload
  };
}

export function drawerChangeDocType(payload) {
  return {
    type: "@drawer/CHANGE_DOCTYPE",
    payload
  };
}

