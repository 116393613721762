import produce from "immer";

const INITIAL_STATE = {
  client: {
    id: "",
    data: [],
    informations: {
      data: [],
      total: "",
      validados: "",
      enviados: "",
      required: "",
      in_analysis: "",
      sent: "",
      analyzed: "",
      approved: "",
      reproved: "",
    },
  },
};

export default function clientReview(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@clientReview/UPDATE_Client_REQUEST":
        break;
      case "@clientReview/UPDATE_Client_SUCCESS":
        draft.client.id = action.payload.data.id;
        draft.client.id = action.payload.data.documents;
        draft.client.data = action.payload.data;
        draft.client.informations = action.payload.data.informations;

        const {
          validated_documents,
          documents_sent,
          required,
          in_analysis,
          sent,
          analyzed,
          approved,
          reproved,
        } = action.payload.data.informations;

        const [enviados, total] = documents_sent.split("/");
        draft.client.informations.total = total;
        draft.client.informations.enviados = enviados;
        draft.client.informations.validados = validated_documents;
        draft.client.informations.required = required;
        draft.client.informations.in_analysis = in_analysis;
        draft.client.informations.sent = sent;
        draft.client.informations.analyzed = analyzed;
        draft.client.informations.approved = approved;
        draft.client.informations.reproved = reproved;
        break;
      case "@auth/SIGN_OUT": {
        draft.client.id = "";
        draft.client.data = [];
        break;
      }
      default:
    }
  });
}
