import styled from "styled-components";
import CustomButton from "../Button";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 0.12rem 0;

  > span {
    margin: 0 0.5rem;
  }

  > h3 {
    font-family: "Roboto";
  }

  > p {
    margin: 0 0.5rem;
  }
`;

export const ButtonsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  width: 100%;
`;

export const Button = styled(CustomButton)`
  width: 100%;
  color: #fff;
`;
